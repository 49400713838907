import React, { useEffect } from 'react';
import Admin from 'app/layouts/DashboardLayout';
import NoData from 'components/NoData';
import { useAppContext } from 'context/AppContext';
import InviteesTable from 'components/InviteesTable';
import SendInvite from 'components/SendInvite';

const Invites = () => {
  // State
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  // Hooks
  const { fetchInvites, invites } = useAppContext();

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <>
      {/* <div className="home content"> */}
      <SendInvite isOpen={open} setOpen={setOpen} />
      <div id="visitors-today" className="mb-6 space-y-10">
        <div className="h-1/2">
          <div className="grid grid-cols-2 w-full mb-10">
            <div className="flex items-center">
              <p className="section-header">Invitees</p>
            </div>
            <div className="flex justify-between space-x-5">
              <input
                type="text"
                className="input"
                placeholder="Search invite"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                type="button"
                className="add-visitor-btn btn btn-primary"
                onClick={() => setOpen(true)}
              >
                Send Invite
              </button>
            </div>
          </div>

          <div>
            {invites.length <= 0 && (
              <NoData
                title="No invites yet!"
                body={
                  <>
                    Send an email invitation to people you want to visit the
                    premises. Start by clicking on the{' '}
                    <span
                      className="text-purple cursor-pointer"
                      onClick={() => setOpen(true)}
                    >
                      &ldquo;Send Invite&ldquo;
                    </span>{' '}
                    button above
                  </>
                }
                image="/assets/images/human-characters.svg"
              />
            )}

            {invites.length > 0 && (
              <InviteesTable
                inviteeList={invites.map(item => item.attributes)}
                search={search}
                setAddNewInvite={setOpen}
              />
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Invites;
