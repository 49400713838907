import React, { useState } from 'react';
import NoDataForTable from 'components/NoDataForTable';
import { useAppContext } from 'context/AppContext';
import ViewEmployee from 'components/ViewEmployee';
import { IEmployee, IEnvelop } from 'types/shared';

interface Props {
  employeeList: IEmployee[];
  setAddNewVisitor: any;
  search: string;
}

const EmployeeTable = (props: Props) => {
  const { employeeList, setAddNewVisitor, search } = props;
  const { updateVisitor } = useAppContext();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(
    null,
  );

  const handleRowClick = (type: string, employee: IEmployee) => {
    if (type === 'update') {
      updateVisitor(employee.email); // TODO: change this method call to handle employee update
    }
  };

  return (
    <div className="table-responsive custom-table-responsive">
      <ViewEmployee
        isOpen={selectedEmployee && open ? true : false}
        setOpen={setOpen}
        employee={selectedEmployee}
      />

      <table className="custom-table">
        <thead className="">
          <tr>
            <th scope="col">Name of Employee</th>
            <th scope="col">Email Address</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Department</th>
            <th scope="col">Job Position</th>
            {/* <th scope="col">Role</th> */}
          </tr>
        </thead>
        <tbody>
          {employeeList && employeeList.length < 1 && (
            <NoDataForTable
              title="You haven’t added any employees yet"
              body={
                <>
                  You don’t have any employees in your organization yet. Start
                  by adding a one using the{' '}
                  <span
                    className="text-purple cursor-pointer"
                    onClick={() => setAddNewVisitor(true)}
                  >
                    {' '}
                    “Add Employee{' '}
                  </span>{' '}
                  button above
                </>
              }
              image="/assets/images/no-department.svg"
            />
          )}
          {employeeList &&
            employeeList.length > 0 &&
            employeeList
              .sort((a, b) => b.name.localeCompare(a.name))
              .filter(
                employee =>
                  employee &&
                  (employee.name
                    .toLowerCase()
                    .includes(search ? search.toLowerCase().trim() : '') ||
                    employee.email
                      .toLowerCase()
                      .includes(search ? search.toLowerCase().trim() : '')),
              )
              .map(employee => {
                return (
                  <React.Fragment key={employee.phone}>
                    <tr className="h-3" />
                    <tr
                      tabIndex={0}
                      className="focus:outline-none h-16 rounded cursor-pointer"
                      onClick={() => {
                        setOpen(true);
                        setSelectedEmployee(employee);
                      }}
                    >
                      <td className="px-2">
                        <div className="">{employee.name ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{employee.email ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{employee.phone ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{employee.department ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{employee.position ?? 'N/A'}</div>
                      </td>
                      {/* <td className="px-2">
                        {employee.role ? (
                          <div className="flex justify-center items-center">
                            <span className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded-full px-3 py-1 bg-[#6b0be426] text-base font-normal text-[#6B0BE4] focus:outline-none sm:mt-0 sm:text-sm">
                              {employee.role}
                            </span>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td> */}
                    </tr>
                  </React.Fragment>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
