import React, {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactNode,
  RefObject,
} from 'react';
import { Link, useMatch } from 'react-router-dom';
import { FiPieChart, FiUsers, FiInbox, FiCalendar } from 'react-icons/fi';
import { GoTasklist } from 'react-icons/go';
import { HiOutlineDocumentReport, HiUserCircle } from 'react-icons/hi';
import { FaRegIdCard, FaBell, FaChevronDown } from 'react-icons/fa';
import NavDropDown from '../NavDropDown';
import { useAppContext } from 'context/AppContext';
import RequireAuth from 'app/layouts/RequireAuth';
import { AppRoles } from 'constants/app-data';

interface NavLinkProps {
  href: string;
  label: string;
  icon?: ReactNode;
}

const NavigationLink = forwardRef(
  (props: NavLinkProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { href, label, icon } = props;

    const match = useMatch({
      path: href,
      end: true,
    });

    return (
      <div
        ref={ref}
        className={`flex items-center space-x-4 ${
          match ? 'text-purple' : 'text-[#777777]'
        }`}
      >
        {icon}
        <Link
          to={href}
          className={`${match ? 'text-purple' : 'text-[#777777]'}`}
        >
          {label}
        </Link>
      </div>
    );
  },
);

const SideBar = () => {
  const { currentUser, userLogout } = useAppContext();

  return (
    <aside
      id="sidebar"
      className="h-full bg-white overflow-y-auto py-5 min-w-[20%]"
    >
      <div className="flex justify-between items-center mb-10 px-5 overflow-y-auto">
        <div className="relative w-[300px] h-[80px]">
          <div className="sidebar-img">
            <img
              src="/assets/images/sidebar_logo.svg"
              alt="Vizitors Log Logo"
            />
          </div>
        </div>
        <i
          className="fa fa-times text-lg hidden"
          id="sidebar-icon"
          aria-hidden="true"
        />
      </div>

      <div className="pb-40 px-5 space-y-5">
        <RequireAuth
          allowedRoles={[AppRoles.Admin, AppRoles.FrontDesk, AppRoles.Manager]}
        >
          <NavigationLink
            href="/dashboard"
            label="Dashboard"
            icon={<FiPieChart />}
          />

          <NavigationLink
            href="/visitors"
            label="Visitors"
            icon={<FiUsers />}
          />

          <NavigationLink
            href="/attendance"
            label="Employee Attendance"
            icon={<GoTasklist />}
          />

          <RequireAuth allowedRoles={[AppRoles.Admin, AppRoles.Manager]}>
            <NavigationLink
              href="/employees"
              label="Employees"
              icon={<FaRegIdCard />}
            />
          </RequireAuth>

          <NavigationLink
            href="/surveys"
            label="Surveys"
            icon={<HiOutlineDocumentReport />}
          />
          <NavigationLink
            href="/invites"
            label="Invites"
            icon={<FiCalendar />}
          />
        </RequireAuth>
        <RequireAuth allowedRoles={[AppRoles.Admin, AppRoles.Manager]}>
          <NavigationLink
            href="/resources"
            label="Workplace Setup"
            icon={<FiInbox />}
          />
        </RequireAuth>
      </div>
      <hr className="bg-[#d3d1d1]" />
      <div className="pt-6 space-y-2 px-5">
        {/* <div className="flex items-center space-x-4 text-[#777] p-3 rounded-sm">
          <FaBell />
          <a href="#">Notifications</a>
        </div> */}
        <NavDropDown
          className="text-[#777] p-3 rounded-sm"
          navName={
            currentUser
              ? `${currentUser.attributes.firstname} ${currentUser.attributes.lastname}`
              : `User Name`
          }
          options={[
            {
              text: 'Logout',
              onClick: userLogout,
            },
          ]}
          prefix={<HiUserCircle />}
          suffix={<FaChevronDown />}
        />
      </div>
    </aside>
  );
};

export default SideBar;
