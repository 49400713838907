import React from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { IEnvelop, IPurpose } from 'types/shared';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
  purpose: IEnvelop<IPurpose> | null;
}
export default function AddPurpose(props: IProps) {
  const { addNewPurpose, updatePurpose } = useAppContext();

  const initialValues = {
    name: props.purpose?.attributes.name ?? '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Field is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);

    try {
      if (!props.purpose) {
        await addNewPurpose(values);
      } else {
        const newData = {
          id: props.purpose.id,
          name: values.name,
        };
        await updatePurpose(newData);
      }

      helpers.setSubmitting(false);
      helpers.resetForm();

      props.setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Purpose of Visit"
      width={`w-[30%]`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldTouched,
            isValid,
          }) => (
            <Form className="px-2">
              <div className="gap-y-6">
                <div className="row row-cols-1 gap-y-6 mb-10">
                  <div className="row row-cols-1 gap-y-6">
                    <CustomFormikInput
                      label="Purpose"
                      name="name"
                      type="text"
                      placeholder="Purpose of visit"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 d-flex flex-row-reverse">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting || !isValid}
                >
                  {props.purpose ? 'Update' : 'Add'}
                  {isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={isSubmitting}
                    />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
