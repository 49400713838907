import React from 'react';

const NoDataForTable = props => {
  const { image, title, body } = props;

  return (
    <>
      <tr className="h-3" />
      <tr className="w-full no-data">
        <td colSpan="100">
          <div className="grid grid-cols-2 justify-evenly items-center w-full bg-white rounded-md py-10 px-28">
            <img src={image} alt="Not Available" />
            <div className="col-6">
              <h3 className="section-header mb-2">{title}</h3>
              <p className=" whitespace-normal">{body}</p>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default NoDataForTable;
