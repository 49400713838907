import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import moment from 'moment';
import { IInvite } from 'types/shared';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
}

export default function SendInvite(props: IProps) {
  const {
    sendInvite,
    allDepartments,
    allPurposes,
    allEmployees,
    fetchEmployees,
  } = useAppContext();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const initialValues = {
    fullname: '',
    phone: '',
    email: '',
    employee_id: '',
    purpose_id: '',
    date: '',
    time: '',
  };
  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Full Name is required'),
    phone: Yup.string()
      .min(10, 'Too Short!')
      .required('Phone number is required'),
    email: Yup.string().email().required('Email is required'),
    employee_id: Yup.string().required('Host is required'),
    purpose_id: Yup.string().required('Purpose is required'),
    date: Yup.string().required('Invite date is required'),
    time: Yup.string().required('Invite time is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    try {
      await sendInvite(values);

      helpers.setSubmitting(false);
      helpers.resetForm();

      props.setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Enter invite details"
      width={`w-[70%]`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="px-2">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 gap-y-5">
                <CustomFormikInput
                  label="Full Name"
                  name="fullname"
                  type="text"
                  placeholder="Prince Perry"
                />

                <CustomFormikInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="name@email.com"
                />

                <CustomFormikInput
                  label="Phone Number"
                  name="phone"
                  type="text"
                  placeholder="024XXXXXXX"
                />

                <CustomFormikInput
                  className="col-start-1"
                  type="select"
                  options={allEmployees.map(x => ({
                    label: x.attributes.name,
                    value: x.id,
                  }))}
                  label="Host"
                  name="employee_id"
                  placeholder="select"
                  handleOnBlur={e => setFieldTouched(e)}
                  handleOnChange={(name, value) => setFieldValue(name, value)}
                />

                <CustomFormikInput
                  type="select"
                  options={allPurposes.map(x => ({
                    label: x.attributes.name,
                    value: x.id,
                  }))}
                  label="Purpose of Visit"
                  name="purpose_id"
                  placeholder="select"
                  handleOnBlur={e => setFieldTouched(e)}
                  handleOnChange={(name, value) => setFieldValue(name, value)}
                />

                <CustomFormikInput
                  type="date"
                  label="Invite Date"
                  name="date"
                  handleOnBlur={e => setFieldTouched(e)}
                />

                <CustomFormikInput
                  type="time"
                  label="Time"
                  name="time"
                  handleOnBlur={e => setFieldTouched(e)}
                />

                <div className="col-span-2 flex flex-row-reverse">
                  <button
                    type="submit"
                    className="btn btn-primary w-fit"
                    disabled={isSubmitting}
                  >
                    Send Invite
                  </button>
                  <button
                    type="button"
                    className="btn btn-outlined w-fit  mr-7"
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
