import React, { useEffect } from 'react';
import NoData from 'components/NoData';
import { useAppContext } from 'context/AppContext';
import AddSurvey from 'components/add-survey';
import { IEnvelop, ISurvey } from 'types/shared';
import DeleteItemModalComponent from 'components/DeleteItemModalComponent';

const Surveys = () => {
  // State
  const [openSurveyForm, setOpenSurveyForm] = React.useState(false);
  const [selectedSurvey, setSelectedSurvey] =
    React.useState<IEnvelop<ISurvey> | null>(null);
  const [isDelete, setIsDelete] = React.useState(false);

  // Hooks
  const { allSurveys, deleteSurvey, fetchSurveys } = useAppContext();

  useEffect(() => {
    fetchSurveys();
  }, []);

  return (
    <>
      <div className="home content">
        <div id="visitors-today" className="mb-6">
          <AddSurvey
            isOpen={(selectedSurvey && openSurveyForm) || openSurveyForm}
            setOpen={x => {
              setOpenSurveyForm(x);
              if (!x) setSelectedSurvey(null);
            }}
            survey={selectedSurvey}
          />

          <DeleteItemModalComponent
            isOpen={selectedSurvey && isDelete ? true : false}
            setOpen={() => {
              setIsDelete(false);
              setSelectedSurvey(null);
            }}
            itemName={selectedSurvey && selectedSurvey.attributes.url}
            itemType={'Survey'}
            deleteCallback={() => {
              deleteSurvey(selectedSurvey.id);
              setSelectedSurvey(null);
              setIsDelete(false);
            }}
          />

          <div className=" space-y-10">
            <div className="h-1/2">
              <div className="flex justify-between w-full mb-10">
                <div className="flex items-center">
                  <p className="section-header">Surveys</p>
                </div>
                <button
                  type="button"
                  className="w-fit btn btn-primary"
                  onClick={() => setOpenSurveyForm(true)}
                >
                  Add Survey
                </button>
              </div>

              <div>
                {allSurveys.length <= 0 && (
                  <NoData
                    title="Add a survey to collect feedback from visitors"
                    body={
                      <>
                        Create a Google form , Typeform or any survey form
                        you’re comfortable.{' '}
                        <span
                          className="text-purple cursor-pointer"
                          onClick={() => setOpenSurveyForm(true)}
                        >
                          Add the link
                        </span>{' '}
                        here and we will automatically send it to your visitors
                        to collect feedback. button above
                      </>
                    }
                    image="/assets/images/no-survey.svg"
                  />
                )}
                {allSurveys.length >= 1 &&
                  allSurveys.map(survey => (
                    <div key={survey.id} className="space-y-5">
                      <div className="flex justify-between items-center bg-white rounded px-3 py-2 mb-3">
                        <div>{survey.attributes.url}</div>
                        <div className="flex flex-nowrap space-x-3">
                          {/* <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedSurvey(survey);
                                setOpenSurveyForm(true);
                              }}
                            >
                              Edit
                            </button>
                          </div> */}
                          <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedSurvey(survey);
                                setIsDelete(true);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Surveys;
