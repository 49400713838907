import { Listbox } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { IDropdownProps } from 'types/shared';
import { FiChevronDown } from 'react-icons/fi';
import Select from 'react-select';

interface Props {
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: IDropdownProps[];
  paddingTop?: number;
  borderRadius?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  isDisabled?: boolean;
  hasError?: boolean;
  background?: string;
  value: IDropdownProps | IDropdownProps[] | null;
  onChange(newValue: IDropdownProps | IDropdownProps[] | null): void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  isMulti?: boolean;
  defaultValue?: IDropdownProps | IDropdownProps[] | null;
}

const CustomSelectComponent = (props: Props) => {
  const formatGroupLabel = (data: any) => (
    <div className="border-b">
      <span className="text-xs text-gray-400">{data.label}</span>
    </div>
  );

  const formatOptionLabel = (data: { label: string; value: string }) => (
    <div>
      <span className="text-xs">{data.label}</span>
    </div>
  );

  // const customStyles: StylesConfig = {};

  return (
    <Select
      components={{ IndicatorSeparator: () => null }}
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      styles={{
        placeholder: provided => ({
          ...provided,
          color: '#8E8E8E',
          fontSize: '14px',
          margin: 0,
        }),
        valueContainer: provided => ({
          ...provided,
          paddingLeft: props.paddingLeft ?? 20,
          paddingRight: props.paddingRight ?? 20,
          paddingTop: props.paddingTop ?? 16,
          paddingBottom: props.paddingBottom ?? 16,
          fontSize: '14px',
          margin: 0,
          outline: 'none',
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: `${props.borderRadius ?? 12}px`,
          borderColor:
            props.hasError && !state.hasValue
              ? '#dc3545'
              : 'rgb(229, 231, 235)',
          boxShadow: 'none',
          backgroundColor: props.background ?? 'transparent',
          borderWidth: '1px',
          outline: 'none',
          '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 1px #6B0BE4',
          },
          '&:hover': {
            outline: 'none',
            boxShadow: '0 0 0 1px #6B0BE4',
          },
        }),
        input: provided => ({
          ...provided,
          margin: 0,
          padding: 0,
          outline: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#fff' : '#000',
          backgroundColor: state.isSelected ? '#6B0BE4' : '#fff',
          '&:hover': {
            outline: 'none',
            backgroundColor: state.isSelected ? '#6B0BE4' : '#6B0BE40A',
          },
        }),
      }}
      isDisabled={props.isDisabled}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.defaultValue}
      options={props.options.sort(function (a, b) {
        const nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      })}
      placeholder={props.placeholder}
    />
  );
};

export default CustomSelectComponent;
