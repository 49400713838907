import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  IAuthUser,
  IVisitor,
  IDepartment,
  IRole,
  IPurpose,
  IEmployee,
  IInvite,
  IRegisterUser,
  IEnvelop,
  IPermissionForRole,
  ISurvey,
  IAttendance,
  IAttendanceEnvelop,
  IDateRange,
} from 'types/shared';
import { useToast } from 'hooks/useToast';
import { redirect } from 'react-router-dom';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { getUserData, setItem, setUserData } from 'services/localStorage';
import { ValidationError } from 'yup';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';

export interface IAppContext {
  http: AxiosInstance;
  isLoading: boolean;
  currentUser: IEnvelop<IAuthUser>;
  allVisitorsList: IEnvelop<IVisitor>[];
  currentVisitorsCount: number;
  allDepartments: IEnvelop<IDepartment>[];
  allSurveys: IEnvelop<ISurvey>[];
  allRoles: IEnvelop<IRole>[];
  appPermissions: IEnvelop<IPermissionForRole>[];
  allPurposes: IEnvelop<IPurpose>[];
  allEmployees: IEnvelop<IEmployee>[];
  allEmployeeAttendanceList: IAttendanceEnvelop<IAttendance[]>;
  todaysVisitorsList: IEnvelop<IVisitor>[];
  searchResults: IEnvelop<IVisitor>[];
  visitor: IEnvelop<IVisitor>;
  invites: IEnvelop<IInvite>[];
  // user
  userLogin(email: string, password: string): void;
  userRegister(data: IRegisterUser): void;
  userLogout(): void;
  getCurrentUser(): void;
  submitEmail(email: string): Promise<boolean>;
  resetPassword(data: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
  }): Promise<boolean>;
  // visitor
  setVisitor: React.Dispatch<React.SetStateAction<IEnvelop<IVisitor>>>;
  // setSearchResults;
  fetchVisitors(): void;
  updateVisitor(id: string): void;
  addNewVisitor(data): Promise<boolean>;
  // employees
  fetchEmployees(): void;
  addNewEmployee(data: IEmployee): void;
  uploadNewEmployeesFile(data): void;
  // attendanced
  fetchAllEmployeeAttendance(data?): void;
  checkInEmployee(staffId: string): any;
  checkOutEmployee(staffId: string): any;
  // department
  fetchDepartments(): void;
  addNewDepartment(data: { id: string; name: string }): void;
  deleteDepartment(id: string): void;
  updateDepartment(data: { id: string; name: string }): void;
  // purpose
  fetchPurposes(): void;
  addNewPurpose(data: { id: string; name: string }): void;
  updatePurpose(data: { id: string; name: string }): void;
  deletePurpose(id: string): void;
  // survey
  fetchSurveys(): void;
  addNewSurvey(data: { id: string; url: string }): void;
  updateSurvey(data: { id: string; url: string }): void;
  deleteSurvey(id: string): void;
  // role
  fetchAllRoles(): void;
  getAllAppPermissions(): void;
  // misc
  performSearch(query: string): void;
  sendInvite(data): void;
  fetchInvites(): void;
  showSubscriptionNotice: boolean;
  setShowSubscriptionNotice: React.Dispatch<React.SetStateAction<boolean>>;
  subscribeToBasicPlan(): void;
}

interface AppProps {
  children: ReactNode;
}

const AppContext = createContext<IAppContext | null>(null);

const AppProvider = ({ children }: AppProps) => {
  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getUserData()?.attributes.token ?? ''}`,
    },
  });
  const notify = useToast();

  // const navigate = useNavigate();

  const [theme, setTheme] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [currentUser, setCurrentUser] = useState<IEnvelop<IAuthUser> | null>(
    null,
  );

  const [visitor, setVisitor] = useState<IEnvelop<IVisitor> | null>(null);
  const [allVisitorsList, setAllVisitorsList] = useState<IEnvelop<IVisitor>[]>(
    [],
  );
  const [currentVisitorsCount, setCurrentVisitorsCount] = useState<number>();

  const [todaysVisitorsList, setTodaysVisitorsList] = useState<
    IEnvelop<IVisitor>[]
  >([]);

  const [allDepartments, setAllDepartments] = useState<IEnvelop<IDepartment>[]>(
    [],
  );

  const [allRoles, setAllRoles] = useState<IEnvelop<IRole>[]>(
    JSON.parse(sessionStorage.getItem('app_roles')) ?? [],
  );
  const [appPermissions, setAppPermissions] = useState<
    IEnvelop<IPermissionForRole>[]
  >(JSON.parse(sessionStorage.getItem('app_permissions')) ?? []);

  const [allPurposes, setAllPurposes] = useState<IEnvelop<IPurpose>[]>([]);

  const [allSurveys, setAllSurveys] = useState<IEnvelop<ISurvey>[]>([]);

  const [allEmployees, setAllEmployees] = useState<IEnvelop<IEmployee>[]>([]);

  const [allEmployeeAttendanceList, setAllEmployeeAttendanceList] =
    useState<IAttendanceEnvelop<IAttendance[]> | null>(null);

  const [searchResults, setSearchResults] = useState<IEnvelop<IVisitor>[]>([]);

  const [invites, setInvitesList] = useState<IEnvelop<IInvite>[]>([]);

  const [showSubscriptionNotice, setShowSubscriptionNotice] =
    useState<boolean>(false);

  const { setError } = useHandleError({
    setSubscriptionNoticeVisibility: setShowSubscriptionNotice,
  });

  useEffect(() => {
    if (currentUser) {
      currentUser.attributes &&
        setShowSubscriptionNotice(!currentUser.attributes.subscription.active);
    }
  }, [currentUser]);

  useEffect(() => {
    const count = allVisitorsList.filter(
      x =>
        x.attributes.logout_time === null ||
        x.attributes.logout_time === undefined ||
        x.attributes.logout_time === '',
    ).length;
    setCurrentVisitorsCount(count);
  }, [allVisitorsList]);

  // useEffect(() => {
  //   getAllAppPermissions();
  // }, []);

  const performSearch = (query: string) => {
    const lowerValue = query?.toLowerCase();
    if (todaysVisitorsList) {
      const nameRes = todaysVisitorsList.filter(visitor =>
        visitor?.attributes.name?.toLowerCase().includes(lowerValue),
      );
      const phone = todaysVisitorsList.filter(visitor =>
        visitor?.attributes.phone?.toLowerCase().includes(lowerValue),
      );
      const host = todaysVisitorsList.filter(visitor =>
        visitor?.attributes.host?.toLowerCase().includes(lowerValue),
      );
      const combinedRes = [...nameRes, ...phone, ...host];
      const newRes = Array.from(new Set(combinedRes));
      setSearchResults(newRes);
    }
  };

  // User Methods
  async function userLogin(email: string, password: string) {
    setIsLoading(true);
    try {
      const _result = await http.post('/login', {
        email,
        password,
      });

      if (_result) {
        setUserData(_result.data.data);
        setCurrentUser(_result.data.data);
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        const newError = (error as AxiosError).response.data as ValidationError;
        Object.values(newError).forEach(error =>
          notify({
            message: `${error}`,
            type: 'error',
          }),
        );
      }
    }
  }

  async function userRegister(data: IRegisterUser) {
    setIsLoading(true);

    try {
      const _result = await http.post('/register', data);

      if (_result) {
        setUserData(_result.data.data);
        setCurrentUser(_result.data.data);
      }

      setIsLoading(false);

      notify({
        message: 'Register Success',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        const newError = (error as AxiosError).response.data as ValidationError;

        Object.values(newError).forEach(error =>
          notify({
            message: `${error}`,
            type: 'error',
          }),
        );
      }
    }
  }

  async function userLogout() {
    setIsLoading(true);

    try {
      sessionStorage.clear();
      const _result = await http.post('/V1/logout');
      sessionStorage.clear();
      window.location.href = '/login';

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function getAllAppPermissions() {
    setIsLoading(true);

    try {
      const _result = await http.get('/V1/permissions');

      if (_result) {
        setItem('app_permissions', _result.data.data);
        setAppPermissions(_result.data.data);
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function getCurrentUser() {
    setIsLoading(true);

    if (currentUser === null) {
      const _result = getUserData();
      if (_result === undefined) {
        redirect('/login');
        return;
      }
      setCurrentUser(_result);
    }

    setIsLoading(false);
  }

  async function submitEmail(email: string) {
    setIsLoading(true);
    try {
      const res = await http.post(`/forgot-password`, { email });

      if (res.status <= 201) {
        notify({
          message:
            'Email sent Successfully. Kindly check your email to continue.',

          type: 'success',
        });
        setIsLoading(false);

        return true;
      }

      return false;
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        const newError = (error as AxiosError).response.data;
        notify({
          message: `${newError}`,
          type: 'error',
        });
      }
    }
  }

  async function resetPassword(data: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
  }) {
    setIsLoading(true);
    try {
      const res = await http.post(`/reset-password`, data);

      if (res.status <= 201) {
        notify({
          message: 'Password reset Successfully. Login to continue.',
          type: 'success',
        });
        setIsLoading(false);
        window.location.href = '/login';

        return true;
      }

      return false;
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        const newError = ((error as AxiosError).response.data as any).message;
        notify({
          message: `${newError}`,
          type: 'error',
        });
      }
    }
  }

  // Visitor Methods
  async function fetchVisitors() {
    setIsLoading(true);

    try {
      const _result = await http.get(`/V1/visitors`);
      setAllVisitorsList([..._result.data.data]);
      const todaysVizs = _result.data.data.filter((item: any) => {
        return (
          item &&
          new Date(item.attributes.login_time).toDateString() ===
            new Date().toDateString()
        );
      });
      setTodaysVisitorsList([...todaysVizs]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function addNewVisitor(data) {
    setIsLoading(true);
    try {
      const res = await http.post(`/V1/visitors`, data);
      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchVisitors();

      setIsLoading(false);

      notify({
        message: 'Visitor Logged Successfully',

        type: 'success',
      });
      return true;
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);

        return false;
      }
    }
  }

  async function updateVisitor(id: string) {
    setIsLoading(true);

    try {
      const res = await http.put(`/V1/visitors/${id}`, {
        logout_time: new Date(Date.now())
          .toISOString()
          .slice(0, 19)
          .replace('T', ' '),
      });

      const guy = todaysVisitorsList.find(x => x.id === id);
      // guy.time_out = new Date(Date.now())
      //     .toISOString()
      //     .slice(0, 19)
      //     .replace('T', ' ');

      // const newList = todaysVisitorsList.filter(x => x.visitorId === id);
      // newList.push(guy);
      // setVisitorsList(newList);

      await fetchVisitors();

      setIsLoading(false);

      notify({
        message: 'Visitor Updated Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Employee Methods
  async function addNewEmployee(data: IEmployee) {
    setIsLoading(true);
    // const newVisitor = createVisitor(data);

    try {
      const res = await http.post(`/V1/employees`, data);

      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchEmployees();

      setIsLoading(false);

      notify({
        message: 'Employee Added Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function uploadNewEmployeesFile(file: File) {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('employees_file', file, file.name);

    try {
      const res = await http.post(`/V1/uploademployees`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await fetchEmployees();

      setIsLoading(false);

      notify({
        message: 'Employees Added Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function fetchEmployees() {
    setIsLoading(true);

    try {
      const _result = await http.get(`/V1/employees`);
      setAllEmployees([..._result.data.data]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function fetchAllEmployeeAttendance(dateRange?: IDateRange) {
    setIsLoading(true);

    try {
      const _result = await http.get(
        `/V1/employee/attendance?employee=all&start=${
          dateRange?.start ?? moment(Date.now()).format('YYYY-MM-DD')
        }&end=${dateRange?.end ?? moment(Date.now()).format('YYYY-MM-DD')}`,
      );
      setAllEmployeeAttendanceList({ ..._result.data.data });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      console.log('in: ', error);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function checkInEmployee(staffId: string) {
    setIsLoading(true);

    try {
      const _result = await http.post(`/V1/employee/checkin`, {
        staffId,
      });
      await fetchAllEmployeeAttendance();

      setIsLoading(false);

      notify({
        message: 'Employee Check-In Successful',
        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function checkOutEmployee(staffId: string) {
    setIsLoading(true);

    try {
      const _result = await http.put(`/V1/employee/checkout`, {
        staffId,
      });
      await fetchAllEmployeeAttendance();

      setIsLoading(false);

      notify({
        message: 'Employee Check-Out Successful',
        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Department Methods
  async function fetchDepartments() {
    setIsLoading(true);

    try {
      // const _result = await mainService.getVisitorsList();
      const _result = await http.get(`/V1/departments`);

      setAllDepartments([..._result.data.data]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function addNewDepartment(data: { id: string; name: string }) {
    setIsLoading(true);
    // const newVisitor = createVisitor(data);

    try {
      const res = await http.post(`/V1/departments`, data);

      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchDepartments();

      setIsLoading(false);

      notify({
        message: 'Department Added Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function updateDepartment(data: { id: string; name: string }) {
    setIsLoading(true);
    try {
      const res = await http.put(`/V1/departments/${data.id}`, {
        name: data.name,
      });
      await fetchDepartments();

      setIsLoading(false);

      notify({
        message: 'Department Updated Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function deleteDepartment(id: string) {
    setIsLoading(true);
    try {
      const res = await http.delete(`/V1/departments/${id}`);
      await fetchDepartments();

      setIsLoading(false);

      notify({
        message: 'Department Deleted Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Role Methods
  async function fetchAllRoles() {
    setIsLoading(true);

    try {
      // const _result = await mainService.getAllRoles();
      const _result = await http.get(`/V1/roles`);

      setAllRoles(_result.data.data);
      setItem('app_roles', _result.data.data);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function assignUserRole(data: IRole) {
    setIsLoading(true);
    // const newVisitor = createVisitor(data);

    try {
      // await mainService.assignUserRole(data);

      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchAllRoles();

      setIsLoading(false);

      notify({
        message: 'Role Assigned Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function removeUserRole(id: string) {
    setIsLoading(true);
    try {
      // await mainService.removeUserRole(id);
      // const res = await http.get(`/V1/roles`);

      setIsLoading(false);

      notify({
        message: 'Role Revoked Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Purpose Methods
  async function fetchPurposes() {
    try {
      const _result = await http.get(`/V1/purpose`);

      setAllPurposes([..._result.data.data]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function addNewPurpose(data: { id: string; name: string }) {
    setIsLoading(true);
    // const newVisitor = createVisitor(data);

    try {
      const res = await http.post(`/V1/purpose`, data);

      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchPurposes();

      setIsLoading(false);

      notify({
        message: 'Purpose Added Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function updatePurpose(data: { id: string; name: string }) {
    setIsLoading(true);

    try {
      const res = await http.put(`/V1/purpose/${data.id}`, {
        name: data.name,
      });
      await fetchPurposes();

      setIsLoading(false);

      notify({
        message: 'Purpose Updated Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function deletePurpose(id: string) {
    setIsLoading(true);
    try {
      const res = await http.delete(`/V1/purpose/${id}`);
      await fetchPurposes();

      setIsLoading(false);

      notify({
        message: 'Purpose Deleted Successfully',
        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Survey Methods
  async function fetchSurveys() {
    try {
      const _result = await http.get(`/V1/surveys`);
      console.log(_result);
      setAllSurveys([..._result.data.data]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function addNewSurvey(data: { id: string; url: string }) {
    setIsLoading(true);
    // const newVisitor = createVisitor(data);

    try {
      const res = await http.post(`/V1/surveys`, data);

      // setVisitorsList([...todaysVisitorsList, data]);
      await fetchSurveys();

      setIsLoading(false);

      notify({
        message: 'Survey Added Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function updateSurvey(data: { id: string; url: string }) {
    setIsLoading(true);

    try {
      const res = await http.put(`/V1/surveys/${data.id}`, {
        name: data.url,
      });
      await fetchSurveys();

      setIsLoading(false);

      notify({
        message: 'Survey Updated Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function deleteSurvey(id: string) {
    setIsLoading(true);
    try {
      const res = await http.delete(`/V1/surveys/${id}`);
      await fetchSurveys();

      setIsLoading(false);

      notify({
        message: 'Survey Deleted Successfully',
        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  // Invite Methods
  async function fetchInvites() {
    try {
      const _result = await http.get(`/V1/invites`);
      setInvitesList([..._result.data.data]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function sendInvite(data) {
    setIsLoading(true);
    try {
      const res = await http.post(`/V1/invites`, data);

      await fetchInvites();

      setIsLoading(false);

      notify({
        message: 'Invite Sent Successfully',

        type: 'success',
      });
    } catch (error: any) {
      setIsLoading(false);

      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  async function subscribeToBasicPlan() {
    setIsLoading(true);
    try {
      const res = await http.post(`/V1/subscribe`, {
        plan_id: '2',
      });

      if (res.status === 200) {
        // redirect(res.data.data.attributes.authorization_url);
        window.location.href = res.data.data.attributes.authorization_url;
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  }

  return (
    <AppContext.Provider
      value={{
        http,
        isLoading,
        currentUser,
        appPermissions,
        searchResults,
        invites,
        // user
        userLogin,
        userRegister,
        userLogout,
        getCurrentUser,
        submitEmail,
        resetPassword,
        // visitor
        currentVisitorsCount,
        todaysVisitorsList,
        visitor,
        setVisitor,
        allVisitorsList,
        // setSearchResults,
        fetchVisitors,
        updateVisitor,
        addNewVisitor,
        // employees
        allEmployees,
        fetchEmployees,
        addNewEmployee,
        uploadNewEmployeesFile,
        // attendance
        fetchAllEmployeeAttendance,
        allEmployeeAttendanceList,
        checkInEmployee,
        checkOutEmployee,
        // department
        allDepartments,
        fetchDepartments,
        addNewDepartment,
        deleteDepartment,
        updateDepartment,
        // purpose
        allPurposes,
        fetchPurposes,
        addNewPurpose,
        updatePurpose,
        deletePurpose,
        // survey
        allSurveys,
        fetchSurveys,
        addNewSurvey,
        updateSurvey,
        deleteSurvey,
        // roles
        allRoles,
        fetchAllRoles,
        getAllAppPermissions,
        // misc
        performSearch,
        sendInvite,
        fetchInvites,
        showSubscriptionNotice,
        setShowSubscriptionNotice,
        subscribeToBasicPlan,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be within an AppProvider');
  }

  return context;
};

export { AppContext, AppProvider, useAppContext };
