import { Toast } from 'types/shared';
import { useToastDispatchContext } from '../context/ToastContext';

export function useToast(delay?: number) {
  const dispatch = useToastDispatchContext();

  function toast(props: Omit<Toast, 'id'>) {
    const id = Math.random().toString(36).slice(2, 11);
    dispatch({
      type: 'ADD_TOAST',
      toast: {
        type: props.type,
        message: props.message,
        id,
      },
    });

    setTimeout(() => {
      dispatch({ type: 'DELETE_TOAST', id });
    }, delay ?? 5000);
  }

  return toast;
}
