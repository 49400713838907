import React, { useEffect } from 'react';
import NoData from 'components/NoData';
import { useAppContext } from 'context/AppContext';
import AddDepartment from 'components/AddDepartment';
import AddPurpose from 'components/AddPurpose';
import Modal from 'components/Modal';
import { IEnvelop, IDepartment, IPurpose } from 'types/shared';
import DeleteItemModalComponent from 'components/DeleteItemModalComponent';

const Resources = () => {
  // State
  const [openDepartmentForm, setOpenDepartmentForm] = React.useState(false);
  const [openPurposeForm, setOpenPurposeForm] = React.useState(false);
  const [selectedDepartment, setSelectedDepartment] =
    React.useState<IEnvelop<IDepartment> | null>(null);
  const [selectedPurpose, setSelectedPurpose] =
    React.useState<IEnvelop<IPurpose> | null>(null);
  const [isDelete, setIsDelete] = React.useState(false);

  // Hooks
  const {
    allDepartments,
    allPurposes,
    deletePurpose,
    deleteDepartment,
    fetchPurposes,
    fetchDepartments,
  } = useAppContext();

  useEffect(() => {
    fetchPurposes();
    fetchDepartments();
  }, []);

  return (
    <>
      <div className="home content">
        <div id="visitors-today" className="mb-6">
          <AddDepartment
            isOpen={
              (selectedDepartment && openDepartmentForm) || openDepartmentForm
            }
            setOpen={x => {
              setOpenDepartmentForm(x);
              if (!x) setSelectedDepartment(null);
            }}
            department={selectedDepartment}
          />

          <AddPurpose
            isOpen={(selectedPurpose && openPurposeForm) || openPurposeForm}
            setOpen={x => {
              setOpenPurposeForm(x);
              if (!x) setSelectedPurpose(null);
            }}
            purpose={selectedPurpose}
          />

          <DeleteItemModalComponent
            isOpen={selectedDepartment && isDelete ? true : false}
            setOpen={() => {
              setIsDelete(false);
              setSelectedDepartment(null);
            }}
            itemName={selectedDepartment && selectedDepartment.attributes.name}
            itemType={'Department'}
            deleteCallback={() => {
              deleteDepartment(selectedDepartment.id);
              setSelectedDepartment(null);
              setIsDelete(false);
            }}
          />

          <DeleteItemModalComponent
            isOpen={selectedPurpose && isDelete ? true : false}
            setOpen={() => {
              setIsDelete(false);
              setSelectedPurpose(null);
            }}
            itemName={selectedDepartment && selectedPurpose.attributes.name}
            itemType={'Purpose'}
            deleteCallback={() => {
              deletePurpose(selectedPurpose.id);
              setSelectedPurpose(null);
              setIsDelete(false);
            }}
          />

          <div className=" space-y-10">
            <div className="h-1/2">
              <div className="flex justify-between w-full mb-10">
                <div className="flex items-center">
                  <p className="section-header">Departments</p>
                </div>
                <button
                  type="button"
                  className="w-fit btn btn-primary"
                  onClick={() => setOpenDepartmentForm(true)}
                >
                  Add Department
                </button>
              </div>

              <div>
                {allDepartments.length <= 0 && (
                  <NoData
                    title="No departments yet!"
                    body={
                      <>
                        You have not added any department to your workspace,
                        start by adding one using the{' '}
                        <span
                          className="text-purple cursor-pointer"
                          onClick={() => setOpenDepartmentForm(true)}
                        >
                          &ldquo;Add Department&ldquo;
                        </span>{' '}
                        button above
                      </>
                    }
                    image="/assets/images/no-department.svg"
                  />
                )}
                {allDepartments.length >= 1 &&
                  allDepartments.map(department => (
                    <div key={department.id} className="space-y-5">
                      <div className="flex justify-between items-center bg-white rounded px-3 py-2 mb-3">
                        <div>{department.attributes.name}</div>
                        <div className="flex flex-nowrap space-x-3">
                          <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedDepartment(department);
                                setOpenDepartmentForm(true);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedDepartment(department);
                                setIsDelete(true);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="h-1/2">
              <div className="flex justify-between w-full mb-10">
                <div className="flex items-center">
                  <p className="section-header">Purpose of visit</p>
                </div>
                <button
                  type="button"
                  className="w-40 btn btn-primary"
                  onClick={() => setOpenPurposeForm(true)}
                >
                  Add Purpose
                </button>
              </div>

              <div>
                {allPurposes.length <= 0 && (
                  <NoData
                    title="No purpose of visit added!"
                    body={
                      <>
                        You have not added any purpose of visit to your
                        workspace, start by adding one using the{' '}
                        <span
                          className="text-purple cursor-pointer"
                          onClick={() => setOpenDepartmentForm(true)}
                        >
                          &ldquo;Add Purpose&ldquo;
                        </span>{' '}
                        button above
                      </>
                    }
                    image="/assets/images/no-purpose.svg"
                  />
                )}

                {allPurposes.length >= 1 &&
                  allPurposes.map(purpose => (
                    <div key={purpose.id} className="space-y-5">
                      <div className="flex justify-between items-center bg-white rounded px-3 py-2 mb-3">
                        <div>{purpose.attributes.name}</div>
                        <div className="flex flex-nowrap space-x-3">
                          <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedPurpose(purpose);
                                setOpenPurposeForm(true);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded px-2 py-2 bg-transparent text-base font-normal focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setSelectedPurpose(purpose);
                                setIsDelete(true);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Resources;
