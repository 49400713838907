import React from 'react';
import NoDataForTable from 'components/NoDataForTable';
import { useAppContext } from 'context/AppContext';
import AddVisitor from 'components/AddVisitor';
import ViewVisitor from 'components/ViewVisitor';
import moment from 'moment';
import { IEnvelop, IVisitor } from 'types/shared';

interface IProps {
  visitorList: IEnvelop<IVisitor>[];
  search: string;
  setOpen(value: boolean): void;
  isOpen: boolean;
}

const VisitorsTable = (props: IProps) => {
  const { visitorList, setOpen, isOpen, search } = props;
  const { updateVisitor } = useAppContext();

  const [selectedVisitor, setSelectedVisitor] =
    React.useState<IEnvelop<IVisitor>>();

  const handleRowClick = (type: string, visitor: IEnvelop<IVisitor>) => {
    if (type === 'view') {
      setSelectedVisitor(visitor);
    }
    if (type === 'update') {
      updateVisitor(visitor.id);
    }
  };

  return (
    <div className="table-responsive custom-table-responsive">
      <ViewVisitor
        isOpen={selectedVisitor ? true : false}
        visitor={selectedVisitor}
        setOpen={value => setSelectedVisitor(!value && null)}
      />

      <table className="custom-table">
        <thead>
          <tr>
            {/* <th scope="col">
                            <label className="control control--checkbox">
                                <input
                                    type="checkbox"
                                    className="js-check-all"
                                />
                                <div className="control__indicator" />
                            </label>
                        </th> */}

            <th scope="col">Name of Visitor</th>
            <th scope="col">Phone</th>
            <th scope="col">Purpose</th>
            <th scope="col">Host</th>
            <th scope="col">Time In</th>
            <th scope="col">Time Out</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {visitorList && visitorList.length < 1 ? (
            <NoDataForTable
              title="You haven’t added any visitors today"
              body={
                <>
                  You don’t have any visitors in your office at the moment.
                  Start by adding a visitor using the{' '}
                  <span
                    className="text-purple cursor-pointer"
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    “Add Visitor”{' '}
                  </span>{' '}
                  button above
                </>
              }
              image="/assets/images/human-characters.svg"
            />
          ) : (
            visitorList &&
            visitorList
              .sort(
                (a, b) =>
                  new Date(b.attributes.login_time).getTime() -
                  new Date(a.attributes.login_time).getTime(),
              )
              .filter(
                visitor =>
                  visitor &&
                  visitor.attributes.name
                    .toLowerCase()
                    .includes(search ? search.toLowerCase().trim() : ''),
              )
              .map(visitor => {
                const visitorData = visitor.attributes;
                return (
                  <React.Fragment key={visitor.id}>
                    <tr className="h-3" />
                    <tr>
                      <td>{visitorData.name ?? 'N/A'}</td>
                      <td>
                        <a href={`tel:${visitorData.phone}`}>
                          {visitorData.phone ?? `N/A`}
                        </a>
                      </td>
                      <td>
                        {(visitorData.purpose && visitorData.purpose) ?? 'N/A'}
                      </td>
                      <td>{visitorData.host ?? 'N/A'}</td>
                      <td className="whitespace-nowrap">
                        {visitorData.login_time
                          ? moment(
                              new Date(visitorData.login_time).getDate() ===
                                new Date().getDate()
                                ? new Date(visitorData.login_time).getTime()
                                : new Date(visitorData.login_time).getDate(),
                            ).format('LT')
                          : 'N/A'}
                      </td>
                      <td className="whitespace-nowrap">
                        {visitorData.logout_time
                          ? moment(
                              new Date(visitorData.logout_time).getTime(),
                            ).format('LT')
                          : 'on-site'}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {!visitorData.logout_time ? (
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-full inline-flex justify-center rounded-full px-3 py-1 bg-red-100 text-base font-normal text-red-700 hover:text-red-500 focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => handleRowClick('update', visitor)}
                            >
                              Sign Out
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-100 inline-flex justify-center rounded-full px-3 py-1 bg-[#6b0be426] text-base font-normal text-[#6B0BE4] focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() => handleRowClick('view', visitor)}
                            >
                              View
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VisitorsTable;
