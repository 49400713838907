import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

interface DateRangePickerProps {
  onChange: (range: [string]) => void;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const newFormattedDates = dates.map(date =>
      date ? moment(date).format('YYYY-MM-DD') : date,
    );
    props.onChange(newFormattedDates);
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      filterDate={isWeekday}
      dateFormat="yyyy-MM-dd"
      placeholderText="Select date range"
      isClearable={true}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      showPopperArrow={false}
      className={`flex-grow flex-auto px-3 py-4 w-full rounded-xl bg-white`}
    />
  );
}
