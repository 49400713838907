import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';

interface Props {
  navName: string;
  options: { text: string; onClick: any }[];
  suffix: any;
  prefix: any;
  className: string;
}

export default function NavDropDown(props: Props) {
  const { navName, options, suffix, prefix, className } = props;

  const [isOpen, setOpen] = useState(false);

  const toggling = () => setOpen(!isOpen);

  const onOptionClicked = (e: any) => () => {
    setOpen(false);
    e();
  };

  return (
    <div className={`${className}`}>
      <div
        onClick={toggling}
        className="flex items-center space-x-4 cursor-pointer"
      >
        {prefix && prefix}
        <span>{navName}</span>
        {suffix && suffix}
      </div>
      {isOpen && (
        <div className="">
          <ul className="m-0 box-border first:pt-3">
            {options &&
              options.map(option => (
                <li
                  className=" list-none mb-1 ml-6 cursor-pointer"
                  onClick={onOptionClicked(option.onClick)}
                  key={Math.random()}
                >
                  {option.text}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

NavDropDown.propTypes = {
  navName: PropTypes.string,
  options: PropTypes.array,
  prefix: PropTypes.element,
  suffix: PropTypes.element,
};

NavDropDown.defaultProps = {
  navName: '',
  options: [],
};
