import React, {
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
} from 'react';

interface ComposeProps {
  components: Array<JSXElementConstructor<PropsWithChildren<any>>>;
  children: ReactNode | null;
}

const ContextProviderComposer = (props: ComposeProps) => {
  return (
    <>
      {props.components.reduceRight(
        (AccumulatedComponents, CurrentComponent) => {
          return <CurrentComponent>{AccumulatedComponents}</CurrentComponent>;
        },
        props.children,
      )}
    </>
  );
};
export default ContextProviderComposer;
