import React, { useEffect, useState } from 'react';
import AuthLayout from 'app/layouts/AuthLayout';
import { useToast } from 'hooks/useToast';
import axios from 'axios';
import useHandleError from 'hooks/useHandleError';
import { useAppContext } from 'context/AppContext';
import { ClipLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const notify = useToast();
  const { setError } = useHandleError();
  const { http } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params) {
      const verificationUrl = params.get('email_verify_url');
      const signature = params.get('signature');
      const url = `${verificationUrl}&signature=${signature}`;
      if (verificationUrl && signature) {
        console.log(`url: ${verificationUrl}\nSig: ${signature}`);
        verifyEmail(url);
      }
    }
  }, [location]);

  const verifyEmail = async (url: string) => {
    try {
      const res = await http.request({
        method: 'GET',
        url: url,
      });

      if (res.status === 200) {
        notify({
          message: res.data.message,
          type: 'success',
        });
        navigate('/dashboard');
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  };

  const handleOnClick = async () => {
    setIsLoading(true);
    try {
      const res = await http.post(`/email/verification/notification`);

      if (res.status === 200) {
        setEmailSent(true);
        notify({
          message: 'Request made successfully',
          type: 'success',
        });
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setEmailSent(true);

      if (axios.isAxiosError(error) && error.response) {
        setError(error);
      }
    }
  };

  return (
    <AuthLayout bgImagePath="/assets/images/login_banner.png">
      <div className="flex flex-col w-fit mx-auto items-center justify-center h-full gap-y-10">
        <div className="text-center flex flex-col gap-y-5">
          <h5 className="text-purple text-2xl font-bold mb-4">
            Oops! Your email hasn't been verified
          </h5>
          <p>Verify your email to continue using the application</p>
        </div>
        {emailSent ? (
          <div className="flex flex-col text-center text-purple">
            <p>Verification email sent.</p>
            <p>
              Kindly check your email and click on the link to finish the
              verification process
            </p>
          </div>
        ) : (
          <button
            className={`btn btn-primary w-full cursor-pointer font-semibold text-white `}
            onClick={() => handleOnClick()}
          >
            <span>Request Verification Link</span>
            {isLoading && (
              <ClipLoader size={15} color={'#fff'} loading={isLoading} />
            )}
          </button>
        )}
      </div>
    </AuthLayout>
  );
};
export default VerifyEmail;
