import React, { useEffect } from 'react';
import ContextProviderComposer from 'components/ComposeProvider';
import { redirect, useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';

interface AuthLayoutProps {
  children: React.ReactNode;
  bgImagePath: string;
}

const AuthLayout = ({ children, bgImagePath }: AuthLayoutProps) => {
  return (
    <div className="h-[100vh] w-full commons_font">
      <div className="grid md:grid-cols-2 sm:grid-cols-1 h-full w-full">
        <div className="col-span-1 hidden md:flex items-center justify-center">
          <div className="auth-banner flex items-center justify-center relative">
            <img
              src={bgImagePath}
              alt=""
              className="absolute w-full h-full top-0 bottom-0 left-0 right-0 -z-10"
            />
            <div className="relative w-[404px] h-[96px]">
              <img src="/assets/images/logo.png" alt="logo" />
            </div>
          </div>
        </div>
        <div className="col-span-1 flex min-h-full py-6 px-4 lg:px-0 overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
