import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate, Navigate, Link, redirect } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { Formik, useField, Form, FormikHelpers } from 'formik';
import CustomFormikInput from 'components/CustomFormikInput';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ClipLoader } from 'react-spinners';
import AuthLayout from 'app/layouts/AuthLayout';
import axios from 'axios';
import { useToast } from 'hooks/useToast';

const ForgotPassword = () => {
  const { submitEmail } = useAppContext();
  const notify = useToast();

  useDocumentTitle('VizitorsLog - Forgot Password');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
  });

  const initialValues = {
    email: '',
  };

  const onSubmit = async (values, helpers: FormikHelpers<any>) => {
    helpers.setSubmitting(true);
    const res = submitEmail(values.email);

    res.then(val => {
      if (val) {
        helpers.resetForm();
        redirect('/dashboard');
      }
    });
  };

  return (
    <AuthLayout bgImagePath="/assets/images/login_banner.png">
      <div className="mx-auto my-auto w-fit h-fit">
        <div className="w-full space-y-20">
          <div className="flex flex-col text-center items-center justify-center">
            <h1 className="text-purple text-5xl font-bold mb-4">
              Forgot Password
            </h1>
            <h6 className=" w-2/3">
              A password reset link will be sent to your email to continue the
              process
            </h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {formik => (
              <div className="flex flex-col space-y-10">
                <CustomFormikInput
                  label="Work Email"
                  name="email"
                  type="text"
                  placeholder="work@email.com"
                  disabled={formik.isSubmitting}
                />

                <h6>
                  Remember your password?{' '}
                  <Link className="link" to="/login">
                    Log in
                  </Link>
                </h6>

                <button
                  type="button"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => formik.handleSubmit()}
                  className={`btn btn-primary w-full ${
                    formik.isValid || formik.isSubmitting
                      ? 'bg-green cursor-pointer'
                      : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                  } font-semibold text-white `}
                >
                  <span>Submit</span>
                  {formik.isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={formik.isSubmitting}
                    />
                  )}
                </button>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ForgotPassword;
