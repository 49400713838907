import React, { useEffect } from 'react';
import Admin from 'app/layouts/DashboardLayout';
import VisitorsOverview from 'components/VisitorsOverview';
import PageHeader from 'components/PageHeader';
import VisitorsToday from 'components/VisitorsToday';
import { useAppContext } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';

const Dashboard = () => {
  const { user } = useAuth();
  const {
    currentUser,
    getCurrentUser,
    fetchDepartments,
    fetchPurposes,
    fetchAllRoles,
    fetchVisitors,
    fetchEmployees,
  } = useAppContext();

  useEffect(() => {
    if (window.location.pathname.includes('dashboard') && currentUser) {
      getCurrentUser();
      fetchVisitors();
      fetchDepartments();
      fetchPurposes();
      fetchAllRoles();
      fetchEmployees();
    }
  }, []);

  return (
    <>
      <PageHeader company={currentUser?.attributes.organization} />
      <VisitorsOverview />
      <VisitorsToday />
    </>
  );
};
export default Dashboard;
