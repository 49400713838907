import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { Formik } from 'formik';
import CustomFormikInput from 'components/CustomFormikInput';
import useDocumentTitle from 'hooks/useDocumentTitle';
import AuthLayout from 'app/layouts/AuthLayout';
import { ClipLoader } from 'react-spinners';

const Register = () => {
  const { currentUser, userRegister, getCurrentUser } = useAppContext();
  const navigate = useNavigate();
  useDocumentTitle('VizitorsLog - Register');

  const validationSchema = Yup.object().shape({
    organization: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Company Name is required'),
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Firstname is required'),
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Lastname is required'),
    email: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const initialValues = {
    organization: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  };

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);

    await userRegister(values);

    // helpers.setSubmitting(false);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    currentUser && navigate('/dashboard');
  }, [currentUser]);

  return (
    <AuthLayout bgImagePath="/assets/images/register_banner.png">
      <div className="mx-auto my-auto w-fit h-fit">
        <div className="w-full space-y-16">
          <div className="text-center">
            <h1 className="text-purple text-5xl font-bold mb-4">Get Started</h1>
            <h6>
              Already registered?{' '}
              <Link className="link" to="/login">
                Log In
              </Link>
            </h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {formik => (
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                <div className="col-span-2">
                  <CustomFormikInput
                    label="Company Name"
                    name="organization"
                    type="text"
                    placeholder="e.g Kauri Africa LTD"
                    disabled={formik.isSubmitting}
                  />
                </div>

                <div className="col-span-2 md:col-span-1 form-group position-relative mb-4">
                  <CustomFormikInput
                    label="First Name"
                    name="firstname"
                    type="text"
                    placeholder="Perry"
                    disabled={formik.isSubmitting}
                  />
                </div>

                <div className="col-span-2 md:col-span-1 form-group position-relative mb-4">
                  <CustomFormikInput
                    label="Last Name"
                    name="lastname"
                    type="text"
                    placeholder="Ntem"
                    disabled={formik.isSubmitting}
                  />
                </div>

                <div className="col-span-2">
                  <CustomFormikInput
                    label="Work Email"
                    name="email"
                    type="text"
                    placeholder="work@email.com"
                    disabled={formik.isSubmitting}
                  />
                </div>

                <div className="col-span-2">
                  <CustomFormikInput
                    type="password"
                    label="Password"
                    name="password"
                    placeholder="**********"
                    disabled={formik.isSubmitting}
                  />
                </div>

                <div className="col-span-2">
                  <button
                    type="button"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => formik.handleSubmit()}
                    className={`btn btn-primary w-full ${
                      formik.isValid || formik.isSubmitting
                        ? 'bg-green cursor-pointer'
                        : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                    } font-semibold text-white `}
                  >
                    <span>Create a free account</span>
                    {formik.isSubmitting && (
                      <ClipLoader
                        size={15}
                        color={'#fff'}
                        loading={formik.isSubmitting}
                      />
                    )}
                  </button>

                  <p className="text-center mt-3">
                    Free 14-day trial - All features included - no credit card
                    required
                  </p>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Register;
