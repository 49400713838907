import axios from 'axios';
import { IAuthUser, IEnvelop } from 'types/shared';

const getUserData = () => {
  const userString = sessionStorage.getItem('userData');
  const userDetail: IEnvelop<IAuthUser> = JSON.parse(userString);

  return userDetail;
};

const setUserData = (data: IAuthUser) => {
  sessionStorage.setItem('userData', JSON.stringify(data));
};

const setItem = (key: string, data: string) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

const removeItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export { setUserData, getUserData, setItem };
