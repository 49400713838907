import { useToastDispatchContext } from 'context/ToastContext';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Toast } from 'types/shared';

export default function ToastComponent({ type, message, id }: Toast) {
  const dispatch = useToastDispatchContext();
  return (
    <div className="md:w-2/6 lg:w-3/12 h-auto bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden p-4 my-2">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {type && (
            <AiOutlineInfoCircle
              className={`h-6 w-6 ${type === 'success' && 'text-green-400'} ${
                type === 'error' && 'text-red-400'
              } ${type === 'info' && 'text-yellow-400'} `}
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm text-gray-500">{message}</p>
        </div>
        <div className="ml-4 flex-shrink-0 flex">
          <button
            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              dispatch({ type: 'DELETE_TOAST', id });
            }}
          >
            <span className="sr-only">Close</span>
            &times;
          </button>
        </div>
      </div>
    </div>
  );
}
