import localStorageService from './localStorage.service';

interface IProps {
  errorCode?: number | string;
  shouldSignIn?: boolean;
  callBackUrl?: string;
}
export default function ErrorPageNavigator({
  errorCode,
  shouldSignIn,
}: IProps): void {
  if (shouldSignIn) {
    localStorageService.deleteAllCookies();
    window.location.pathname = '/';
  }

  switch (errorCode) {
    // case 401: {
    //     Router.push("");
    //     if (shouldSignIn && callBackUrl) {
    //         sessionStorage.setItem("callback", callBackUrl);
    //         signOut({ callbackUrl: callBackUrl });
    //     }
    //     break;
    // }
    case 403: {
      window.location.pathname = '/403';
      break;
    }
    case 500: {
      window.location.pathname = '/500';
      break;
    }
  }
}
