import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
}

export default function AddAttendance(props: IProps) {
  const { checkInEmployee } = useAppContext();

  const initialValues = {
    staffId: '',
  };
  const validationSchema = Yup.object().shape({
    staffId: Yup.string().required('Staff ID is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);

    try {
      const res = await checkInEmployee(values.staffId);

      if (res !== null) {
        helpers.resetForm();
      }
      // helpers.setSubmitting(false);

      // setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Record Attendance"
      width={`w-[30%]`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          isInitialValid={false}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldTouched,
            submitForm,
            handleSubmit,
          }) => (
            <Form className="px-2">
              <div className="">
                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    label="Staff ID"
                    name="staffId"
                    type="text"
                    placeholder="ABC123"
                    handleOnChange={(name, value) => setFieldValue(name, value)}
                  />
                </div>
              </div>
              <div className="flex flex-row-reverse">
                <button
                  type="button"
                  disabled={isSubmitting || !isValid}
                  onClick={() => handleSubmit()}
                  className={`btn btn-primary  ${
                    isValid || isSubmitting
                      ? 'bg-purple cursor-pointer'
                      : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                  } w-full font-semibold text-white `}
                >
                  <span>Submit</span>
                  {isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={isSubmitting}
                    />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
