import useFetch from 'hooks/useFetch';
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import {
  IRole,
  IAuthUser,
  IPermissionForRole,
  IRoleWithPermissions,
  IEnvelop,
} from 'types/shared';
import axios from 'axios';
import { getUserData } from 'services/localStorage';

interface ProviderProps {
  children?: ReactNode;
}

interface ContextProps {
  appRoles: IEnvelop<IRoleWithPermissions>[];
  getPermissionsByRole(roleId: string): IPermissionForRole[];
  userPermissions: IPermissionForRole[];
  userHasPermission(permissionName?: string): boolean;
  user: IAuthUser | null;
}

const AuthContext = createContext<ContextProps>({
  appRoles: [],
  getPermissionsByRole: () => [],
  userHasPermission: () => false,
  userPermissions: [],
  user: null,
});

const AuthorizationProvider = ({ children }: ProviderProps) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  const [userPermissions, setUserPermissions] = useState<IPermissionForRole[]>(
    [],
  );
  const [appRoles, setAppRoles] = useState<IEnvelop<IRoleWithPermissions>[]>(
    [],
  );
  const [user, setUser] = useState<IAuthUser | null>(null);

  //Get user data from local storage
  const [userData, setUserData] = useState<IEnvelop<IAuthUser>>();

  const { data: rolesWithPermissions } = useFetch({
    token: userData?.attributes.token as string,
    url: `${process.env.API_ENDPOINT}/V1/roles-permissions`,
    params: [],
    shouldFetch: shouldFetch,
  });

  useEffect(() => {
    const data = getUserData();
    setUserData(data);
  }, []);

  useEffect(() => {
    if (userData) {
      setShouldFetch(true);
      setUser((userData as unknown as IEnvelop<IAuthUser>).attributes);

      // TODO: get user permissions here
      const role = (userData as unknown as IEnvelop<IAuthUser>).attributes
        .role[0];
      const permissions = getPermissionsByRole(role);
      setUserPermissions(permissions);
    }
  }, [userData]);

  useEffect(() => {
    if (rolesWithPermissions) {
      setAppRoles([
        ...rolesWithPermissions.data.data,
      ] as IEnvelop<IRoleWithPermissions>[]);
    }
  }, [rolesWithPermissions]);

  const getPermissionsByRole = (roleName: string): IPermissionForRole[] => {
    const roles = appRoles.filter(item => item.attributes.name === roleName);
    const permissions = roles[0].attributes.permissions;
    return permissions;
  };

  const userHasPermission = (permissionName?: string): boolean => {
    return userPermissions.some(item => {
      return !permissionName
        ? true
        : item.name.toUpperCase() == permissionName.toUpperCase();
    });
  };

  const contextValues: ContextProps = {
    user,
    appRoles,
    getPermissionsByRole,
    userPermissions,
    userHasPermission,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthorizationProvider;
export const useAuth = () => useContext(AuthContext);
