import React from 'react';
import ReactDOM from 'react-dom';
import { useAppContext } from 'context/AppContext';

const PageHeader = props => {
  const { company } = props;

  return (
    <header className="flex items-center mb-10">
      <div id="menu-icon">
        <i className="fa fa-bars" aria-hidden="true" />
      </div>

      <div className="">
        <h1 className="text-4xl font-bold text-purple-200">
          Welcome, {company || ' Company'}
        </h1>
      </div>
    </header>
  );
};

export default PageHeader;
