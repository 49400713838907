import { Transition } from '@headlessui/react';
import SideBar from 'components/SideBar';
import SubscriptionNotice from 'components/SubscriptionNotice';
import { useAppContext } from 'context/AppContext';
import useScriptTag from 'hooks/useScriptTag';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const AdminLayout = () => {
  // useScriptTag('https://screensaside0i1.marbleflows.com/flows/3632');
  const navigate = useNavigate();

  const {
    currentUser,
    getCurrentUser,
    showSubscriptionNotice,
    setShowSubscriptionNotice,
  } = useAppContext();
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(true);

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    !currentUser && navigate('/login');
  }, [currentUser]);

  return (
    <div className="main-layout">
      <SubscriptionNotice
        isOpen={showSubscriptionNotice}
        setOpen={setShowSubscriptionNotice}
      />
      <Transition
        as="div"
        className="sticky top-0"
        show={toggleSidebar}
        enter="transform transition duration-[200ms]"
        enterFrom="opacity-0 -translate-x-full"
        enterTo="opacity-100 scale-100"
        leave="transform duration-[200ms] transition ease-in-out"
        leaveFrom="opacity-100 -translate-x-full scale-100 "
        leaveTo="opacity-0 -translate-x-0"
      >
        <SideBar />
      </Transition>

      <main className="bg-[#f2f2f2] px-14 pt-20 max-h-screen w-full overflow-y-auto transform transition duration-[200ms] overflow-x-hidden">
        <button
          aria-expanded="true"
          aria-controls="sidebar"
          className="absolute lg:hidden text-purple-200 p-2 hover:bg-purple-200 hover:text-white cursor-pointer rounded"
          onClick={() => setToggleSidebar(prev => !prev)}
        >
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="w-6 h-6 hidden"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
