import React from 'react';

const NotAuthorized = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="col">
          <h5>Oops!</h5>
          <p>You're not authorized to view this page</p>
        </div>
      </div>
    </>
  );
};
export default NotAuthorized;
