import React, { Dispatch } from 'react';
import Modal from 'components/Modal';
import { IAttendance } from 'types/shared';
import { useAppContext } from 'context/AppContext';
import { ClipLoader } from 'react-spinners';

interface IProps {
  data?: IAttendance;
  isOpen: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}
export default function SubscriptionNotice(props: IProps) {
  const { subscribeToBasicPlan, isLoading, currentUser } = useAppContext();
  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Subscription Notice"
      width="w-[50%]"
    >
      <div className="flex flex-col gap-y-10">
        <p>
          Your {currentUser && currentUser.attributes?.subscription.plan}{' '}
          subscription plan has ended. Subscribe to continue enjoying the
          service.
        </p>

        <div className="col-span-2 d-flex flex-row-reverse">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading}
            onClick={() => subscribeToBasicPlan()}
          >
            <span>Subscribe</span>
            {isLoading && (
              <ClipLoader size={15} color={'#fff'} loading={isLoading} />
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
