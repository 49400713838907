import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
}

export default function AddVisitor(props: IProps) {
  const { addNewVisitor, allPurposes, allEmployees, fetchEmployees } =
    useAppContext();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const idTypes = [
    { label: 'National ID', value: 'national_id' },
    { label: "Driver's License", value: 'driver_license' },
    { label: 'SSNIT', value: 'ssnit' },
  ];

  const initialValues = {
    name: '',
    phone: '',
    id_type: '',
    id_number: '',
    purpose_id: '',
    employee_id: '',
    // login_time: new Date(Date.now())
    //   .toISOString()
    //   .slice(0, 19)
    //   .replace('T', ' '),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Full Name is required'),
    phone: Yup.string()
      .min(10, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Phone number is required'),
    id_type: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('ID Type is required'),
    id_number: Yup.string()
      .min(8, 'Too Short!')
      .max(15, 'Too Long!')
      .required('ID Number is required'),
    purpose_id: Yup.string().required('Visit Purpose is required'),
    employee_id: Yup.number().required('Host is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);

    try {
      const res = await addNewVisitor(values);

      if (res) {
        helpers.resetForm();
      }
      // helpers.setSubmitting(false);

      // setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Enter visitor's details"
      width={`w-[70%]`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          isInitialValid={false}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldTouched,
            submitForm,
            handleSubmit,
          }) => (
            <Form className="px-2">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-20">
                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    label="Full Name"
                    name="name"
                    type="text"
                    placeholder="Prince Perry"
                    handleOnChange={(name, value) => setFieldValue(name, value)}
                  />
                </div>

                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    label="Phone Number"
                    name="phone"
                    type="text"
                    placeholder="024XXXXXXX"
                    disabled={isSubmitting}
                    handleOnChange={(name, value) => setFieldValue(name, value)}
                  />
                </div>

                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    type="select"
                    options={idTypes}
                    label="ID Type"
                    name="id_type"
                    placeholder="select"
                    onBlur={() => setFieldTouched}
                    disabled={isSubmitting}
                  />
                </div>

                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    label="ID Number"
                    name="id_number"
                    type="text"
                    placeholder=""
                    disabled={isSubmitting}
                  />
                </div>

                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    type="select"
                    options={
                      allPurposes &&
                      allPurposes.map(x => ({
                        label: x.attributes.name,
                        value: x.id,
                      }))
                    }
                    label="Purpose of Visit"
                    name="purpose_id"
                    placeholder="select"
                    onBlur={() => setFieldTouched}
                    disabled={isSubmitting}
                  />
                </div>

                <div className="form-group position-relative mb-4">
                  <CustomFormikInput
                    type="select"
                    options={allEmployees.map(x => ({
                      label: `${x.attributes.name}`,
                      value: +x.id,
                    }))}
                    label="Host (Employee Visited)"
                    name="employee_id"
                    placeholder="select"
                    onBlur={() => setFieldTouched}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="col-span-2 d-flex flex-row-reverse">
                <button
                  type="button"
                  disabled={isSubmitting || !isValid}
                  onClick={() => handleSubmit()}
                  className={`btn btn-primary  ${
                    isValid || isSubmitting
                      ? 'bg-purple cursor-pointer'
                      : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                  } w-full font-semibold text-white `}
                >
                  <span>Add Visitor</span>
                  {isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={isSubmitting}
                    />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
