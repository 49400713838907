import React from 'react';
import ReactDOM from 'react-dom';
import VisitorsTable from 'components/VisitorsTable';
import AddVisitor from 'components/AddVisitor';
import { useAppContext } from 'context/AppContext';

const VisitorsToday = () => {
  // const { visitors } = props;
  const { todaysVisitorsList } = useAppContext();

  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  return (
    <div id="visitors-today" className="mb-6">
      <AddVisitor isOpen={open} setOpen={setOpen} />
      <div className="grid grid-cols-2 w-full mb-10">
        <div className="flex items-center">
          <p className="section-header">Visitors for today</p>
        </div>
        <div className="flex justify-between space-x-5">
          <input
            type="text"
            className="search-visitor input"
            placeholder="Search visitor"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <button
            type="button"
            className="add-visitor-btn btn btn-primary"
            onClick={() => setOpen(true)}
          >
            Add Visitor
          </button>
        </div>
      </div>

      <VisitorsTable
        setOpen={setOpen}
        search={search}
        visitorList={todaysVisitorsList}
        isOpen={open}
      />
    </div>
  );
};

export default VisitorsToday;
