import React, { useEffect, useState } from 'react';
import EmployeeAttendanceTable from 'components/EmployeeAttendanceTable';
import { useAppContext } from 'context/AppContext';
import AddAttendance from 'components/AddAttendance';
import DateRangePicker from 'components/DateRangePicker';

const EmployeeAttendance = () => {
  // State
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState<string[]>(null);

  // Hooks
  const { fetchAllEmployeeAttendance, allEmployeeAttendanceList } =
    useAppContext();

  useEffect(() => {
    if (dateRange) {
      const [start, end] = dateRange;
      fetchAllEmployeeAttendance({ start, end });
    } else fetchAllEmployeeAttendance();
  }, [dateRange]);

  return (
    <>
      <AddAttendance isOpen={open} setOpen={setOpen} />

      <div className="home content">
        <div id="visitors-today" className="mb-6">
          <div className="flex space-x-10 w-full mb-10">
            <div className="flex items-center">
              <p className="section-header">Employee Attendance List</p>
            </div>
            <div className="flex grow justify-between space-x-5">
              <input
                type="text"
                className="search-visitor input"
                placeholder="Search records"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />

              <DateRangePicker onChange={dates => setDateRange(dates)} />

              <button
                type="button"
                className="add-visitor-btn btn btn-primary"
                onClick={() => setOpen(true)}
              >
                Add Attendance
              </button>
            </div>
          </div>

          <EmployeeAttendanceTable
            setOpen={setOpen}
            search={search}
            // dateRange={dateRange}
            list={allEmployeeAttendanceList?.attendance ?? []}
          />
        </div>
      </div>
    </>
  );
};
export default EmployeeAttendance;
