import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { Switch } from '@headlessui/react';
import { ClipLoader } from 'react-spinners';
import Dropzone from 'react-dropzone';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
}
enum tabs {
  single = 'Single employee',
  import = 'Import employees',
}
export default function AddEmployee(props: IProps) {
  const { addNewEmployee, uploadNewEmployeesFile, allDepartments, allRoles } =
    useAppContext();
  const [selectedEmployeesFile, setSelectedEmployeesFile] =
    useState<File | null>(null);

  const [tab, setTab] = useState<string>(tabs.single);

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    department_id: '',
    position: '',
    role: '',
    isUser: false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Full Name is required'),
    phone: Yup.string()
      .min(10, 'Too Short!')
      .required('Phone number is required'),
    email: Yup.string().email().required('Email is required'),
    department_id: Yup.number().required('Depaartment is required'),
    isUser: Yup.boolean().required(),
    position: Yup.string()
      .min(3, 'Too short!')
      .required('Position is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    const newValues = values;
    try {
      if (newValues.role !== '') {
        newValues.user = true;
        await addNewEmployee(newValues);
      } else {
        await addNewEmployee(newValues);
      }
      helpers.setSubmitting(false);
      helpers.resetForm();

      props.setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Enter employee's details"
      width={`w-[70%]`}
    >
      <div>
        <div className="flex mb-14 border-b">
          <div className="flex h-full flex-1 items-end">
            <button
              onClick={() => setTab(tabs.single)}
              className={`w-fit p-[10px] ${
                tab === tabs.single
                  ? 'border-b border-purple text-purple-300'
                  : 'border-0 text-gray-300'
              }  text-base px-5 font-semibold`}
            >
              Single Employee
            </button>
            <button
              onClick={() => setTab(tabs.import)}
              className={`w-fit p-[10px] ${
                tab === tabs.import
                  ? 'border-b border-purple text-purple-300'
                  : 'border-0 text-gray-300'
              } text-base px-5 font-semibold`}
            >
              Import Employee
            </button>
          </div>
        </div>
        {tab === tabs.single && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              validateField,
              setFieldValue,
              setFieldTouched,
              isValid,
            }) => (
              <Form className="px-2">
                <div className="gap-y-6 grid grid-flow-row">
                  <div className="grid grid-cols-2 gap-y-6 mb-10">
                    <div className="col-span-2">
                      <div className="col-span-2 font-normal text-[#6B0BE4] text-sm">
                        Personal Details
                      </div>
                      <div className="grid grid-cols-2 row-cols-md-2 gap-y-6 gap-x-5">
                        <CustomFormikInput
                          label="Full Name"
                          name="name"
                          type="text"
                          placeholder="Prince Perry"
                          disabled={isSubmitting}
                        />

                        <CustomFormikInput
                          label="Phone Number"
                          name="phone"
                          type="text"
                          placeholder="024XXXXXXX"
                          disabled={isSubmitting}
                        />

                        <CustomFormikInput
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="name@email.com"
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-y-6 mb-10">
                    <div className="col-span-2">
                      <div className="font-normal text-[#6B0BE4] text-sm">
                        Work Details
                      </div>
                      <div className="grid grid-cols-2 row-cols-md-2 gap-y-6 gap-x-5">
                        <CustomFormikInput
                          type="select"
                          options={
                            allDepartments &&
                            allDepartments.map(x => ({
                              label: x.attributes.name,
                              value: x.id,
                            }))
                          }
                          label="Department"
                          name="department_id"
                          placeholder="select"
                          handleOnBlur={setFieldTouched}
                          handleOnChange={setFieldValue}
                          disabled={isSubmitting}
                        />

                        <CustomFormikInput
                          label="Job Position"
                          name="position"
                          type="text"
                          placeholder="e.g. Accountant"
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>

                  {/* TODO: Add Is User CHeckbox/Radio btn Here. If True, reveal the roles and permissions dropdown input. */}

                  <div className="grid grid-cols-2 gap-y-6 mb-10">
                    <div className="col-span-2">
                      <div className="font-normal text-[#6B0BE4] text-sm">
                        Roles & Permissions
                      </div>
                    </div>
                    <div className="grid grid-cols-2 row-cols-md-2 gap-y-6 gap-x-5">
                      <div className="space-y-4">
                        <label className="text-red mb-3" htmlFor={'isUser'}>
                          Is System User?
                        </label>
                        <div className="flex space-x-4">
                          <div>No</div>
                          <Switch
                            name="isUser"
                            checked={values.isUser}
                            onChange={value => setFieldValue('isUser', value)}
                            className={`${
                              values.isUser ? 'bg-purple' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            disabled={isSubmitting}
                          >
                            <span className="sr-only">
                              Enable notifications
                            </span>
                            <span
                              className={`${
                                values.isUser
                                  ? 'translate-x-6'
                                  : 'translate-x-1'
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                          <div>Yes</div>
                        </div>
                      </div>
                    </div>
                    {values.isUser && (
                      <CustomFormikInput
                        type="select"
                        options={
                          allRoles &&
                          allRoles.map(x => ({
                            label: x.attributes.name,
                            value: x.attributes.name,
                          }))
                        }
                        label="Role"
                        name="role"
                        placeholder="No role selected"
                        handleOnBlur={setFieldTouched}
                        handleOnChange={setFieldValue}
                        disabled={isSubmitting}
                      />
                    )}
                  </div>
                </div>

                <div className="col-span-2 flex flex-row-reverse">
                  <button
                    type="submit"
                    className="btn btn-primary w-fit"
                    disabled={isSubmitting || !isValid}
                  >
                    Add Employee
                    {isSubmitting && (
                      <ClipLoader
                        size={15}
                        color={'#fff'}
                        loading={isSubmitting}
                      />
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {tab === tabs.import && (
          <>
            <Dropzone
              maxFiles={1}
              accept={{
                'text/csv': [],
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  [],
              }}
              onDrop={acceptedFiles =>
                setSelectedEmployeesFile(acceptedFiles[0])
              }
            >
              {({ getRootProps, getInputProps }) =>
                selectedEmployeesFile ? (
                  <div className="relative flex flex-col w-full items-center justify-between space-y-5">
                    <div>
                      <img src="/assets/images/upload_list.svg" alt="" />
                    </div>
                    <div>
                      Selected file: <span>{selectedEmployeesFile.name}</span>
                    </div>

                    <button
                      className="btn btn-primary w-fit"
                      onClick={() => {
                        uploadNewEmployeesFile(selectedEmployeesFile);
                      }}
                    >
                      Submit selected file
                    </button>
                    <div className="text-xs">OR</div>
                    <button
                      className=" text-red-400 underline"
                      onClick={() => {
                        setSelectedEmployeesFile(null);
                      }}
                    >
                      Remove file
                    </button>
                  </div>
                ) : (
                  <section className="flex justify-center items-center">
                    <div
                      {...getRootProps()}
                      className="space-y-14 flex flex-col justify-center items-center"
                    >
                      <input {...getInputProps()} />
                      <img src="/assets/images/upload_list.svg" alt="" />
                      <div className="text-center space-y-2 max-w-[500px]">
                        <p className=" font-medium text-2xl">
                          Click or drag file to this area to upload
                        </p>
                        <p className="text-gray-300">
                          Upload a CSV file or Excel(.xlsx, .xls) or Google
                          sheet file to upload a list of your employees. Columns
                          should have Fullname, phone number, email, department
                          and job position.
                        </p>
                      </div>
                    </div>
                  </section>
                )
              }
            </Dropzone>
          </>
        )}
      </div>
    </Modal>
  );
}
