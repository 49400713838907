import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Dashboard from './pages/Dashboard';
import Employees from './pages/Employees';
import Invites from './pages/Invites';
import Resources from './pages/Resources';
import Visitors from './pages/Visitors';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import RequireAuth from 'app/layouts/RequireAuth';
import NotAuthorized from './pages/NotAuthorized';
import DashboardLayout from 'app/layouts/DashboardLayout';
import Surveys from './pages/surveys';
import EmployeeAttendance from './pages/EmployeeAttendance';
import { AppRoles } from 'constants/app-data';
import VerifyEmail from './pages/VerifyEmail';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                AppRoles.Admin,
                AppRoles.FrontDesk,
                AppRoles.Manager,
              ]}
              childType="page"
            />
          }
        >
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/visitors" element={<Visitors />} />
            <Route path="/attendance" element={<EmployeeAttendance />} />
            <Route
              element={
                <RequireAuth
                  allowedRoles={[AppRoles.Admin, AppRoles.Manager]}
                  childType="page"
                />
              }
            >
              <Route path="/resources" element={<Resources />} />
              <Route path="/employees" element={<Employees />} />
            </Route>
            <Route path="/surveys" element={<Surveys />} />
            <Route path="/invites" element={<Invites />} />
          </Route>
        </Route>
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
