import React, { useEffect } from 'react';
import Admin from 'app/layouts/DashboardLayout';
import EmployeeTable from 'components/EmployeeTable';
import { useAppContext } from 'context/AppContext';
import AddEmployee from 'components/AddEmployee';

const Employees = () => {
  // State
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  // Hooks
  const { fetchEmployees, allEmployees, updateVisitor } = useAppContext();

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <>
      <div className="home content">
        <div id="visitors-today" className="mb-6">
          <AddEmployee isOpen={open} setOpen={setOpen} />
          <div className="grid grid-cols-2 w-full mb-10">
            <div className="flex items-center">
              <p className="section-header">Employees</p>
            </div>
            <div className="flex justify-between space-x-5">
              <input
                type="text"
                className="input"
                placeholder="Search employee"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                type="button"
                className="add-visitor-btn btn btn-primary"
                onClick={() => setOpen(true)}
              >
                Add Employee
              </button>
            </div>
          </div>

          <EmployeeTable
            setAddNewVisitor={setOpen}
            search={search}
            employeeList={allEmployees.map(item => item.attributes)}
          />
        </div>
      </div>
    </>
  );
};
export default Employees;
