import React from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { ISurvey, IEnvelop } from 'types/shared';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
  survey?: IEnvelop<ISurvey>;
}
export default function AddSurvey(props: IProps) {
  const { addNewSurvey, updateSurvey } = useAppContext();

  const initialValues = {
    url: props.survey?.attributes.url ?? '',
  };
  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Field is required'),
  });

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);

    try {
      if (!props.survey) {
        await addNewSurvey(values);
      } else {
        const newData = {
          id: props.survey.id,
          url: values.url,
        };
        await updateSurvey(newData);
      }

      helpers.setSubmitting(false);
      helpers.resetForm();

      props.setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Enter Survey details"
      width={`w-[30%]`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldTouched,
            isValid,
          }) => (
            <Form className="px-2">
              <div className="gap-y-6">
                <div className="row row-cols-1 gap-y-6 mb-10">
                  <div className="row row-cols-1 gap-y-6">
                    <CustomFormikInput
                      label="Survey Url"
                      name="url"
                      type="text"
                      placeholder="www.some-survey-url.com"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 d-flex flex-nowrap flex-row-reverse">
                <button
                  type="submit"
                  className="btn btn-primary whitespace-nowrap"
                  disabled={isSubmitting || !isValid}
                >
                  {props.survey ? 'Update' : 'Add'}
                  {isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={isSubmitting}
                    />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
