import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { Formik } from 'formik';
import CustomFormikInput from 'components/CustomFormikInput';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ClipLoader } from 'react-spinners';
import AuthLayout from 'app/layouts/AuthLayout';

const Login = () => {
  const { currentUser, userLogin, getCurrentUser } = useAppContext();
  const navigate = useNavigate();
  useDocumentTitle('VizitorsLog - Login');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, 'Too Short!')
      .max(255, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    await userLogin(values.email, values.password);

    // helpers.setSubmitting(false);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    currentUser && navigate('/dashboard');
  }, [currentUser]);

  return (
    <AuthLayout bgImagePath="/assets/images/login_banner.png">
      <div className="mx-auto my-auto w-fit h-fit">
        <div className="w-full space-y-20">
          <div className="text-center">
            <h1 className="text-purple text-5xl font-bold mb-4">
              Welcome Back!
            </h1>
            <h6>
              Not registered?{' '}
              <Link className="link" to="/register">
                Sign Up
              </Link>
            </h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {formik => (
              <div className="grid gap-y-4">
                <CustomFormikInput
                  label="Work Email"
                  name="email"
                  type="text"
                  placeholder="work@email.com"
                  disabled={formik.isSubmitting}
                />

                <CustomFormikInput
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="**********"
                  disabled={formik.isSubmitting}
                />

                <h6>
                  <Link className="link" to="/forgot-password">
                    Forgot password?
                  </Link>
                </h6>

                <button
                  type="button"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => formik.handleSubmit()}
                  className={`btn btn-primary w-full ${
                    formik.isValid || formik.isSubmitting
                      ? 'bg-green cursor-pointer'
                      : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                  } font-semibold text-white `}
                >
                  <span>Login</span>
                  {formik.isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={formik.isSubmitting}
                    />
                  )}
                </button>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Login;
