import React from 'react';
import NoDataForTable from 'components/NoDataForTable';
import { useAppContext } from 'context/AppContext';
import ViewEmployeeAttendanceDetails from 'components/ViewEmployeeAttendanceDetails';
import moment from 'moment';
import { IAttendance } from 'types/shared';

interface IProps {
  list: IAttendance[];
  search: string;
  setOpen(value: boolean): void;
  // dateRange: string[];
}

const EmployeeAttendanceTable = (props: IProps) => {
  const { list, setOpen, search } = props;
  const { checkOutEmployee } = useAppContext();

  const [selectedEmployee, setSelectedEmployee] = React.useState<
    string | number
  >();

  const handleRowClick = (type: string, employeeId: string | number) => {
    if (type === 'view') {
      setSelectedEmployee(employeeId);
    }
    if (type === 'update') {
      checkOutEmployee(employeeId.toString());
    }
  };

  return (
    <div className="table-responsive custom-table-responsive">
      <ViewEmployeeAttendanceDetails
        isOpen={selectedEmployee ? true : false}
        data={undefined}
        setOpen={value => setSelectedEmployee(!value && null)}
      />

      <table className="custom-table">
        <thead>
          <tr>
            <th scope="col">Name of Employee</th>
            <th scope="col">Date</th>
            <th scope="col">Time In</th>
            <th scope="col">Time Out</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length < 1 ? (
            <NoDataForTable
              title="There are no recorded attendances for today"
              body={
                <>
                  There are no recorded employees in your office at the moment.
                  Start by adding a record using the{' '}
                  <span
                    className="text-purple cursor-pointer"
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    “Record Attendance”{' '}
                  </span>{' '}
                  button above
                </>
              }
              image="/assets/images/human-characters.svg"
            />
          ) : (
            list &&
            list
              .sort(
                (a, b) =>
                  new Date(b.checkin).getTime() - new Date(a.checkin).getTime(),
              )
              .filter(
                visitor =>
                  visitor &&
                  visitor.employeeName
                    .toLowerCase()
                    .includes(search ? search.toLowerCase().trim() : ''),
              )
              .map(attendance => {
                return (
                  <React.Fragment
                    key={`${attendance.employeeId}-${attendance.checkin}`}
                  >
                    <tr className="h-3" />
                    <tr>
                      <td>{attendance.employeeName ?? 'N/A'}</td>
                      <td>{attendance.date ?? 'N/A'}</td>
                      <td className="whitespace-nowrap">
                        {attendance.checkin
                          ? moment(
                              new Date(attendance.checkin).getDate() ===
                                new Date().getDate()
                                ? new Date(attendance.checkin).getTime()
                                : new Date(attendance.checkin).getDate(),
                            ).format('LT')
                          : 'N/A'}
                      </td>
                      <td className="whitespace-nowrap">
                        {attendance.checkout
                          ? moment(
                              new Date(attendance.checkout).getTime(),
                            ).format('LT')
                          : 'on-site'}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {!attendance.checkout ? (
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-fit inline-flex justify-center rounded-full px-3 py-1 bg-red-100 text-base font-normal text-red-700 hover:text-red-500 focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() =>
                                handleRowClick(
                                  'update',
                                  attendance.employeeEmail,
                                )
                              }
                            >
                              Sign Out
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="max-w-1/2 whitespace-nowrap w-fit inline-flex justify-center rounded-full px-3 py-1 bg-[#6b0be426] text-base font-normal text-[#6B0BE4] focus:outline-none sm:mt-0 sm:text-sm"
                              onClick={() =>
                                handleRowClick('view', attendance.employeeId)
                              }
                            >
                              View
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeAttendanceTable;
