import React from 'react';
import NoDataForTable from 'components/NoDataForTable';
import { useAppContext } from 'context/AppContext';
import ViewEmployee from 'components/ViewEmployee';
import { IInvite } from 'types/shared';

interface IProps {
  inviteeList: IInvite[];
  setAddNewInvite: any;
  search: string;
}

const InviteesTable = (props: IProps) => {
  const { inviteeList, setAddNewInvite, search } = props;

  const [open, setOpen] = React.useState(false);
  const [selectedInvitee, setSelectedInvitee] = React.useState<IInvite | null>(
    null,
  );

  return (
    <div className="table-responsive custom-table-responsive">
      <table className="custom-table">
        <thead className="">
          <tr>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Host</th>
            <th scope="col">Invite Date</th>
            {/* <th scope="col">Status</th> */}
          </tr>
        </thead>
        <tbody>
          {inviteeList &&
            inviteeList.length > 0 &&
            inviteeList
              .sort((a, b) => b.fullname.localeCompare(a.fullname))
              .filter(
                invitee =>
                  invitee &&
                  (invitee.fullname
                    .toLowerCase()
                    .includes(search ? search.toLowerCase().trim() : '') ||
                    invitee.email
                      .toLowerCase()
                      .includes(search ? search.toLowerCase().trim() : '') ||
                    invitee.phone
                      .toLowerCase()
                      .includes(search ? search.toLowerCase().trim() : '')),
              )
              .map((invitee, index) => {
                return (
                  <React.Fragment key={`${invitee.phone}-${index}`}>
                    <tr className="h-3" />
                    <tr
                      className="focus:outline-none h-16 rounded cursor-pointer"
                      onClick={() => {
                        setOpen(true);
                        setSelectedInvitee(invitee);
                      }}
                    >
                      <td className="px-2">
                        <div className="">{invitee.fullname ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{invitee.email ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{invitee.phone ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">{invitee.employee ?? 'N/A'}</div>
                      </td>
                      <td className="px-2">
                        <div className="">
                          {`${invitee.date} at ${invitee.time}` ?? 'N/A'}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default InviteesTable;
