import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { redirect, useSearchParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import CustomFormikInput from 'components/CustomFormikInput';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ClipLoader } from 'react-spinners';
import AuthLayout from 'app/layouts/AuthLayout';
import { useAppContext } from 'context/AppContext';

const ResetPassword = () => {
  const { resetPassword } = useAppContext();
  const [queryParameters] = useSearchParams();

  useDocumentTitle('VizitorsLog - Reset Password');

  const [token, setToken] = useState(queryParameters.get('token'));

  useEffect(() => {
    setToken(queryParameters.get('token'));
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const initialValues = {
    token: token,
    email: '',
    password: '',
    password_confirmation: '',
  };

  const onSubmit = async (values, helpers: FormikHelpers<any>) => {
    helpers.setSubmitting(true);
    const res = resetPassword(values);
    res.then(val => {
      if (val) {
        helpers.resetForm();
        redirect('/dashboard');
      }
    });
  };

  return (
    <AuthLayout bgImagePath="/assets/images/login_banner.png">
      <div className="mx-auto my-auto w-fit h-fit">
        <div className="w-full space-y-20">
          <div className="flex flex-col text-center items-center justify-center">
            <h1 className="text-purple text-5xl font-bold mb-4">
              Reset Password
            </h1>
            <h6 className=" w-full">Enter and confirm your new password.</h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {formik => (
              <div className="flex flex-col space-y-10">
                <CustomFormikInput
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="example@domain.com"
                  disabled={formik.isSubmitting}
                />

                <CustomFormikInput
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="**********"
                  disabled={formik.isSubmitting}
                />

                <CustomFormikInput
                  type="password"
                  label="Confirm Password"
                  name="password_confirmation"
                  placeholder="**********"
                  disabled={formik.isSubmitting}
                />

                <button
                  type="button"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => formik.handleSubmit()}
                  className={`btn btn-primary w-full ${
                    formik.isValid || formik.isSubmitting
                      ? 'bg-green cursor-pointer'
                      : 'bg-[#E4ECF7] text-[#505780] cursor-not-allowed'
                  } font-semibold text-white `}
                >
                  <span>Submit</span>
                  {formik.isSubmitting && (
                    <ClipLoader
                      size={15}
                      color={'#fff'}
                      loading={formik.isSubmitting}
                    />
                  )}
                </button>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ResetPassword;
