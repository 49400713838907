import React from 'react';
import { useAppContext } from 'context/AppContext';
import './style.css';

const VisitorsOverview = props => {
  const { todaysVisitorsList, currentVisitorsCount } = useAppContext();

  return (
    <div id="visitors-overview">
      <div className="flex mb-6">
        <p className="section-header">Visitors Overview</p>
      </div>
      <div className="grid grid-cols-4 gap-x-12">
        <div className="card">
          <p>Total visits today</p>
          <h1 className="text-purple font-semibold text-5xl">
            {todaysVisitorsList.length ?? 0}
          </h1>
        </div>

        <div className="card">
          <p>Visitors on site</p>
          <h1 className="text-purple font-semibold text-5xl">
            {currentVisitorsCount ?? 0}
          </h1>
        </div>

        {/* <div className="card">
                    <p>Invites</p>
                    <h1 className="card-value">{invites}</h1>
                </div>

                <div className="card">
                    <p>Employees</p>
                    <h1 className="card-value">{employees}</h1>
                </div> */}
      </div>
    </div>
  );
};

export default VisitorsOverview;
