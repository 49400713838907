import { useToastStateContext } from 'context/ToastContext';
import React from 'react';
import ToastComponent from './ToastComponent';

export default function ToastContainer() {
  const { toasts } = useToastStateContext();

  return (
    <div className="absolute top-10 right-10 w-full z-50 flex flex-col-reverse items-end">
      {toasts &&
        toasts.map(toast => (
          <ToastComponent
            id={toast.id}
            key={toast.id}
            type={toast.type}
            message={toast.message}
          />
        ))}
    </div>
  );
}
