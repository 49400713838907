import React, { Dispatch } from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import moment from 'moment';
import { IEnvelop, IVisitor } from 'types/shared';

interface IProps {
  visitor?: IEnvelop<IVisitor>;
  isOpen: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}
export default function ViewEmployeeAttendanceDetails(props: IProps) {
  return (
    <Modal
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      title="Visitor's details"
      width="w-[50%]"
    >
      <div className="grid grid-cols-3 gap-y-6">
        <div className="text-gray-400">Name of visitor</div>
        <div className="col-span-2">
          {props.visitor?.attributes.name ?? 'N/A'}
        </div>

        <div className="text-gray-400">Phone number</div>
        <div className="col-span-2">
          {props.visitor?.attributes.phone ?? 'N/A'}
        </div>

        <div className="text-gray-400">Purpose of visit</div>
        <div className="col-span-2">
          {props.visitor?.attributes.purpose ?? 'N/A'}
        </div>

        <div className="text-gray-400">ID type</div>
        <div className="col-span-2">
          {props.visitor?.attributes.id_type ?? 'N/A'}
        </div>

        <div className="text-gray-400">ID number</div>
        <div className="col-span-2">
          {props.visitor?.attributes.id_number ?? 'N/A'}
        </div>

        <div className="text-gray-400">Host</div>
        <div className="col-span-2">
          {props.visitor?.attributes.host ?? 'N/A'}
        </div>

        <div className="text-gray-400">Time in</div>
        <div className="col-span-2">
          {props.visitor?.attributes.login_time &&
            moment(props.visitor?.attributes.login_time).format(
              'MMM Do YYYY, h:mm:ss a',
            )}
        </div>

        <div className="text-gray-400">Time out</div>
        <div className="col-span-2">
          {props.visitor?.attributes.logout_time &&
            moment(props.visitor?.attributes.logout_time).format(
              'MMM Do YYYY, h:mm:ss a',
            )}
        </div>
      </div>
    </Modal>
  );
}
