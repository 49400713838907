import ToastContainer from 'components/Toasts/ToastContainer';
import React, { Dispatch } from 'react';
import {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  Provider,
} from 'react';
import { Toast, ToastAction, ToastState } from 'types/shared';

interface ProviderProps {
  children?: ReactNode;
}

interface StateContextProps {
  toasts: Toast[];
}

const ToastStateContext = createContext<StateContextProps>({ toasts: [] });
const ToastDispatchContext = createContext<Dispatch<ToastAction>>(
  () => undefined,
);

const ToastReducer = (state: ToastState, action: ToastAction) => {
  switch (action.type) {
    case 'ADD_TOAST': {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case 'DELETE_TOAST': {
      const updatedToasts = state.toasts.filter(e => e.id != action.id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default: {
      throw new Error('unhandled action');
    }
  }
};

const ToastProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(ToastReducer, {
    toasts: [],
  });

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        <ToastContainer />
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

export default ToastProvider;
export const useToastStateContext = () => useContext(ToastStateContext);
export const useToastDispatchContext = () => useContext(ToastDispatchContext);
