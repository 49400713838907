import React, { useState } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { IDropdownProps } from 'types/shared';
import CustomSelectComponent from 'components/CustomSelectComponent';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

interface IProps {
  label?: string;
  isClearable?: boolean;
  options?: IDropdownProps[];
  initialValue?: IDropdownProps | null;
  handleOnChange?: (
    name: string,
    value: IDropdownProps | string | Date | null,
  ) => void;
  handleOnBlur?: any;
  isDisabled?: boolean;
  [x: string]: any;
}

const CustomFormikInput = (props: IProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const [canSee, setCanSee] = useState(false);
  const [selectValue, setSelectValue] = useState<
    IDropdownProps | IDropdownProps[] | null
  >(null);
  const [date, setDate] = useState(new Date());

  const handleTextInputChange = (value: string) => {
    props.handleOnChange && props.handleOnChange(props.name, value);
    formik.setFieldValue(props.name, value);
  };

  const handleSelectChange = (value: IDropdownProps | null) => {
    props.handleOnChange && props.handleOnChange(props.name, value);
    setSelectValue(value);
    formik.setFieldValue(props.name, value.value);
  };

  const handleDateChange = (value: Date | null) => {
    props.handleOnChange && props.handleOnChange(props.name, value);
    setDate(new Date(value?.toString() ?? ''));
    formik.setFieldValue(
      props.name,
      moment(value.toISOString()).format('YYYY-MM-DD').toString(),
    );
  };

  const handleTimeChange = (value: Date | null) => {
    props.handleOnChange && props.handleOnChange(props.name, value);
    setDate(new Date(value?.toString() ?? ''));
    formik.setFieldValue(
      props.name,
      moment(value.toISOString()).format('HH:mm:ss').toString(),
    );
  };

  return (
    <div className={`mb-3 ${props.className}`}>
      <label className="text-red mb-3" htmlFor={props.id || props.name}>
        {props.label}
      </label>
      {(props.type === 'text' || props.type === 'email') && (
        <div className="flex flex-wrap w-full relative input-group">
          <input
            {...field}
            type={props.type === 'email' ? 'email' : 'text'}
            className={`flex-grow flex-auto px-3 py-4 ${
              meta.touched && meta.error ? 'is-invalid' : ''
            }`}
            disabled={props.isDisabled}
            onChange={e => handleTextInputChange(e.target.value)}
          />
        </div>
      )}

      {props.type === 'password' && (
        <div className="flex flex-nowrap w-full relative input-group">
          <input
            {...field}
            type={`${canSee ? 'text' : 'password'}`}
            className={`flex-grow flex-auto rounded-r-none px-3 py-4 ${
              meta.touched && meta.error ? 'is-invalid' : ''
            }`}
            disabled={props.isDisabled}
            onChange={e => handleTextInputChange(e.target.value)}
          />

          <div
            className="flex -mr-px cursor-pointer items-center justify-center px-4"
            onClick={() => setCanSee(prev => !prev)}
          >
            {canSee ? <FaRegEye /> : <FaRegEyeSlash />}
          </div>
        </div>
      )}

      {props.type === 'select' && (
        <CustomSelectComponent
          hasError={meta.error && meta.touched}
          background="#f2f2f2"
          placeholder={props.placeholder ?? ''}
          isClearable={props.isClearable}
          options={props.options ? props.options : []}
          value={props.initialValue ?? selectValue}
          onChange={(value: IDropdownProps) => handleSelectChange(value)}
          onBlur={e => props.handleOnBlur(e)}
          isDisabled={props.isDisabled}
        />
      )}

      {props.type === 'date' && (
        <DatePicker
          selected={date}
          onChange={date => handleDateChange(date)}
          dateFormat="MMM d, yyyy"
          showPopperArrow={false}
          disabled={props.isDisabled}
          className={`flex-grow flex-auto px-3 py-4 w-full ${
            meta.touched && meta.error ? 'is-invalid' : ''
          }`}
        />
      )}

      {props.type === 'time' && (
        <DatePicker
          selected={date}
          onChange={date => handleTimeChange(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          minTime={new Date('7:00')}
          maxTime={new Date('18:00')}
          dateFormat="h:mm aa"
          showPopperArrow={false}
          disabled={props.isDisabled}
          className={`flex-grow flex-auto px-3 py-4 w-full ${
            meta.touched && meta.error ? 'is-invalid' : ''
          }`}
        />
      )}

      {meta.touched && meta.error ? (
        <div className="w-full mt-1 text-sm text-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

CustomFormikInput.prototypes = {
  type: PropTypes.oneOf(['select', 'text', 'password', 'date', 'time']),
};

CustomFormikInput.defaultProps = {
  type: 'text',
};

export default CustomFormikInput;
