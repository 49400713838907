import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppProvider } from 'context/AppContext';
import ToastProvider from 'context/ToastContext';
import ErrorBoundary from 'components/ErrorBoundary';
import App from './app';
import 'index.css';
import ContextProviderComposer from 'components/ComposeProvider';

const app = document.getElementById('root');
if (!app) throw new Error('Failed to find the root element');

const root = createRoot(app);
root.render(
  <ErrorBoundary>
    <ContextProviderComposer components={[ToastProvider, AppProvider]}>
      <App />
    </ContextProviderComposer>
  </ErrorBoundary>,
);
