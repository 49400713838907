import React, { useEffect } from 'react';
import VisitorsTable from 'components/VisitorsTable';
import { useAppContext } from 'context/AppContext';
import AddVisitor from 'components/AddVisitor';

const Visitors = () => {
  // State
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  // Hooks
  const { fetchVisitors, allVisitorsList } = useAppContext();

  useEffect(() => {
    fetchVisitors();
  }, []);

  return (
    <>
      <AddVisitor isOpen={open} setOpen={setOpen} />

      <div className="home content">
        <div id="visitors-today" className="mb-6">
          <div className="grid grid-cols-2 w-full mb-10">
            <div className="flex items-center">
              <p className="section-header">Visitors List</p>
            </div>
            <div className="flex justify-between space-x-5">
              <input
                type="text"
                className="search-visitor input"
                placeholder="Search visitor"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                type="button"
                className="add-visitor-btn btn btn-primary"
                onClick={() => setOpen(true)}
              >
                Add Visitor
              </button>
            </div>
          </div>

          <VisitorsTable
            setOpen={setOpen}
            isOpen={open}
            search={search}
            visitorList={allVisitorsList}
          />
        </div>
      </div>
    </>
  );
};
export default Visitors;
