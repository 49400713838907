import { AxiosError } from 'axios';

import { useEffect, useState } from 'react';
import { useToast } from './useToast';
import ErrorPageNavigator from 'services/errorPageNavigator';

interface HandleErrorProps {
  setSubscriptionNoticeVisibility(value: boolean): void;
}

export default function useHandleError(props?: HandleErrorProps) {
  const notify = useToast();
  // const { setShowSubscriptionNotice } = useAppContext();
  const [error, setError] = useState<AxiosError | undefined | null>(null);

  useEffect(() => {
    if (error && error.response) {
      const errorResponseData = error.response.data as any;
      if (error.code == '402' || error.response.status == 402) {
        props.setSubscriptionNoticeVisibility(true);
        return;
      }

      if (error.code == '403' || error.response.status == 403) {
        console.log(errorResponseData.message);
        if (errorResponseData.message.toLowerCase().includes('access denied')) {
          window.location.pathname = 'not-authorized';

          return;
        } else if (
          errorResponseData.message.toLowerCase().includes('email not verified')
        ) {
          window.location.pathname = 'verify-email';
          return;
        }
      }

      const errors = (error.response.data as any).errors;
      const singleError = (error.response.data as any).error;

      let errorList: string[] = [];

      if (errors) {
        Object.values(errors).forEach(error => {
          errorList = [...errorList, ...(error as string[])];
        });

        if (errorList) {
          errorList.forEach((item: string) =>
            notify({
              message: `${item}`,
              type: 'error',
            }),
          );
        } else {
          if ((error.response.data as any).message) {
            if (
              ((error.response.data as any).message as string)
                .toLowerCase()
                .includes('unauthenticated')
            ) {
              ErrorPageNavigator({ shouldSignIn: true });
              return;
            } else {
              notify({
                message: `${(error.response.data as any).message}`,
                type: 'error',
              });
            }
          } else {
            notify({
              message: `${singleError}`,
              type: 'error',
            });
          }
        }
      } else {
        if ((error.response.data as any).message) {
          if (
            ((error.response.data as any).message as string)
              .toLowerCase()
              .includes('unauthenticated')
          ) {
            ErrorPageNavigator({ shouldSignIn: true });
            return;
          } else {
            notify({
              message: `${(error.response.data as any).message}`,
              type: 'error',
            });
          }
        } else {
          notify({
            message: `${(error.response.data as any).error}`,
            type: 'error',
          });
        }
      }
    }
  }, [error]);

  return { setError };
}
