import { useAppContext } from 'context/AppContext';
import React, { Fragment } from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  allowedRoles: string[];
  children?: ReactElement | ReactElement[];
  childType?: 'component' | 'page';
}

const RequireAuth = ({
  allowedRoles,
  children,
  childType = 'component',
}: Props) => {
  const navigate = useNavigate();

  const { currentUser } = useAppContext();

  const isAuthorized = allowedRoles.some(role =>
    currentUser?.attributes.role.includes(role),
  );

  const [elementToRender, setElementToRender] = useState<
    ReactElement | ReactElement[]
  >();

  useEffect(() => {
    childType === 'page' && !isAuthorized && navigate('/not-authorized');
  }, [currentUser]);

  useEffect(() => {
    if (childType === 'page') {
      if (!currentUser) {
        navigate('/login');
      } else {
        setElementToRender([<Outlet />]);
      }
    } else {
      if (isAuthorized) {
        setElementToRender(children);
      }
    }
  }, [currentUser]);

  return Array.isArray(elementToRender) ? (
    <>
      {elementToRender.map((item, idx) => {
        return <React.Fragment key={idx}>{item}</React.Fragment>;
      })}
    </>
  ) : (
    <>{elementToRender}</>
  );
};

export default RequireAuth;
