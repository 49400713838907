import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Modal from 'components/Modal';
import * as Yup from 'yup';
import { useAppContext } from 'context/AppContext';
import CustomFormikInput from 'components/CustomFormikInput';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isOpen: boolean;
  setOpen(value: boolean): void;
  itemName?: string;
  itemType?: string;
  deleteCallback(): void;
}

export default function DeleteItemModalComponent({
  isOpen,
  setOpen,
  itemName,
  itemType,
  deleteCallback,
}: IProps) {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      title={`Delete ${itemType ?? ''}?`}
      width={`w-[20%]`}
    >
      <div>
        Are you sure you want to delete &apos;
        <strong>{itemName}</strong>
        &apos;? This cannot be undone.
      </div>
      <div className="flex space-x-5 mt-10 items-center">
        {' '}
        <button
          type="button"
          className="btn btn-primary add-visitor-btn btn-primary h-10"
          onClick={() => setOpen}
        >
          No
        </button>
        <button
          type="button"
          className="btn add-visitor-btn h-10 bg-red-500 text-white"
          onClick={deleteCallback}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
}
