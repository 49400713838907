import React from 'react';
import styled from 'styled-components';

const NoData = props => {
  const { image, title, body } = props;

  return (
    <div className="bg-white flex flex-wrap md:flex-nowrap justify-evenly items-center w-full rounded-md py-9 px-28">
      <img src={image} alt="Not Available" className="col w-full" />
      <div className="md:w-full ">
        <h3 className="section-header mb-2">{title}</h3>
        <p>{body}</p>
      </div>
    </div>
  );
};

export default NoData;
