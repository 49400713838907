import React from 'react';
import Modal from 'components/Modal';
import { IEmployee } from 'types/shared';

interface IProps {
  employee: IEmployee | null;
  isOpen: boolean;
  setOpen(value: boolean): void;
}
export default function ViewEmployee({ employee, setOpen, isOpen }: IProps) {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Employee details"
      width={`w-[50%]`}
    >
      <div className="grid grid-cols-3 gap-y-6">
        <div className="text-gray-400">Name of employee</div>
        <div className="col-span-2">{employee?.name ?? 'N/A'}</div>

        <div className="text-gray-400">Phone number</div>
        <div className="col-span-2">{employee?.phone ?? 'N/A'}</div>

        <div className="text-gray-400">Email</div>
        <div className="col-span-2">{employee?.email ?? 'N/A'}</div>

        <div className="text-gray-400">Department</div>
        <div className="col-span-2">{employee?.department ?? 'N/A'}</div>

        <div className="text-gray-400">Job position</div>
        <div className="col-span-2">{employee?.position ?? 'N/A'}</div>
      </div>
    </Modal>
  );
}
