import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment } from 'react';
import Close from '../icons/Close';

interface Props {
  setOpen?: (a: boolean) => void;
  children: React.ReactNode;
  width?: string;
  height?: string;
  disableRoundedCorners?: boolean;
  isOpen?: boolean;
  zIndex?: string;
  title?: React.ReactNode;
  hideCloseButton?: boolean;
}

const Modal = (props: Props) => {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => props.setOpen && props.setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          className={`fixed inset-0 flex flex-col items-center justify-center overflow-y-auto py-12`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              as="div"
              className={`relative max-h-[80%] transform bg-white py-6 pr-0 text-left align-middle shadow-xl transition-all ${
                !props.disableRoundedCorners ? 'rounded-lg' : ''
              } ${props.width ?? 'w-6/12 sm:max-w-[80%]'} ${
                props.height ?? 'h-fit'
              }`}
            >
              <div className="h-full w-full overflow-y-auto">
                {(!props.hideCloseButton || props.title) && (
                  <div className="sticky top-0 z-10 flex justify-between bg-white px-6 pb-4">
                    <Dialog.Title
                      as="div"
                      className="text-lg font-semibold leading-6 text-[#061941]"
                    >
                      {props.title}
                    </Dialog.Title>
                    <div className="">
                      {props.setOpen && !props.hideCloseButton && (
                        <button
                          type="button"
                          onClick={() => props.setOpen && props.setOpen(false)}
                          className="rounded-full bg-[#C4C4C4]/[.3] p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                        >
                          <span className="sr-only">Close</span>
                          <Close />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                <div className=" flex flex-col px-6 ">{props.children}</div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
